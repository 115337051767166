$(function () {
  $('body').on('click', '.fn, #cmenu a', function () {
    var fn = $(this).attr('fn');
    var p1 = $(this).attr('p1');
    var p2 = $(this).attr('p2');
    var p3 = $(this).attr('p3');

    if (p1 == '[taskid]') {
      p1 = $(this).closest('*[taskid]').attr('taskid');
    }
    if (p2 == '[taskid]') {
      p2 = $(this).closest('*[taskid]').attr('taskid');
    }
    if (p3 == '[taskid]') {
      p3 = $(this).closest('*[taskid]').attr('taskid');
    }

    if (p1 == '[text]') {
      p1 = $(this).text();
    }
    if (p2 == '[text]') {
      p2 = $(this).text();
    }
    if (p3 == '[text]') {
      p3 = $(this).text();
    }

    if (fn != undefined) {
      $('#cmenu').css('top', 0);
      $('#cmenu').css('left', -1000);
      $('#cmenu').css('z-index', -2000);

      var f = fn + "('" + p1 + "','" + p2 + "','" + p3 + "')";
      DEBUG && console.log(f);
      eval(f);
    }
  });

  // TASKLISTS
  $('body').on('click', 'div.tasklist div.moreless', function () {
    var $tasklist = $(this).closest('div.tasklist');
    $tasklist.toggleClass('topn');
    if ($tasklist.hasClass('topn')) {
      NIRV.moreless[$tasklist.attr('key')] = 'less';
    } else {
      NIRV.moreless[$tasklist.attr('key')] = 'more';
    }
  });

  $('body').on(
    'click',
    '#main div.tasklist > div.name span.toggle',
    function () {
      var $tasklist = $(this).closest('div.tasklist');
      var key = NIRV.currentview + ':' + $tasklist.attr('key');
      if ($tasklist.hasClass('collapsed')) {
        $tasklist.removeClass('collapsed');
        delete NIRV.collapse[key];
      } else {
        $tasklist.addClass('collapsed');
        NIRV.collapse[key] = true;
      }
      NIRV.refreshMain();
    }
  );

  // SELECTABLES (FOR MULTI-DRAG/DROP)
  // FIX for cursor becoming text on chrome/safari during selectable
  // http://forum.jquery.com/topic/chrome-text-select-cursor-on-drag
  document.onselectstart = function (e) {
    if ($(e.target).is('input') || $(e.target).is('textarea')) {
      /* skip */
      return true;
    } else {
      return false;
    }
  };
});

NIRV.draggable = function () {
  DEBUG && VERBOSE && console.log(' NIRV.draggable()');

  var $selected = $('#main li.task.ui-selected');
  var countselected = $selected.length;

  $('#main .ui-draggable').draggable('destroy');
  NIRV.selecteditems = '';

  if ($selected.length > 1) {
    $selected.each(function () {
      NIRV.selecteditems += ',' + $(this).attr('taskid');
    });
    if (NIRV.selecteditems[0] == ',') {
      NIRV.selecteditems = NIRV.selecteditems.substr(1);
    }
    $selected.draggable({
      helper: function () {
        return $('<div class="ui-widget-header dragthing"></div>');
      },
      cursorAt: { top: 10, left: 8 },
      start: function () {
        $('div.dragthing').html('<span>' + countselected + ' items</span>');
      },
      stop: function () {
        NIRV.selecteditems = '';
      },
    });
  } else {
    NIRV.selecteditems = '';
    $('#main .ui-draggable').draggable('destroy');
  }
};
