$(function () {
  $('body').on('click', '#preferences a.sso-account', function (e) {
    e.preventDefault();
    NIRV.gotoAccount();
  });

  // GLOBAL AREA FILTERING
  $('body').on('click', '#preferences #UIAreaFiltering-inclusive', function () {
    NIRV.setPref('UIAreaFiltering', 'inclusive', 'true');
  });
  $('body').on('click', '#preferences #UIAreaFiltering-exclusive', function () {
    NIRV.setPref('UIAreaFiltering', 'exclusive', 'true');
  });

  // TASK COUNTS
  $('body').on('click', '#preferences #UICounts-default', function () {
    NIRV.setPref('UICounts', '', true);
  });
  $('body').on('click', '#preferences #UICounts-all', function () {
    NIRV.setPref('UICounts', 'all', true);
  });

  // PROJECTS
  $('body').on(
    'click',
    '#preferences #UIDefaultProjectType-parallel',
    function () {
      NIRV.setPref('UIDefaultProjectType', '0', 'true');
    }
  );
  $('body').on(
    'click',
    '#preferences #UIDefaultProjectType-sequential',
    function () {
      NIRV.setPref('UIDefaultProjectType', '1', 'true');
    }
  );

  // LATER
  $('body').on('click', '#preferences #UIEnableLater-yes', function () {
    NIRV.setPref('UIEnableLater', '1', 'true');
  });
  $('body').on('click', '#preferences #UIEnableLater-no', function () {
    NIRV.setPref('UIEnableLater', '0', 'true');
  });

  // INBOX AND AREAS
  $('body').on('click', '#preferences #UIAreaAssignment-yes', function () {
    NIRV.setPref('UIAreaAssignment', '1', 'true');
  });
  $('body').on('click', '#preferences #UIAreaAssignment-no', function () {
    NIRV.setPref('UIAreaAssignment', '0', 'true');
  });

  // RAPID ENTRY
  $('body').on('click', '#preferences #UIEnableRapidEntry-yes', function () {
    NIRV.setPref('UIEnableRapidEntry', '1', 'true');
  });
  $('body').on('click', '#preferences #UIEnableRapidEntry-no', function () {
    NIRV.setPref('UIEnableRapidEntry', '0', 'true');
  });

  // LOGBOOK
  $('body').on('change', '#preferences #UICollectCompleted', function (e) {
    NIRV.setPref('UICollectCompleted', e.target.value, 'true');
  });

  // COMPLETED
  $('body').on(
    'click',
    '#preferences #UIBLeaveCompletedInPlace-yes',
    function () {
      NIRV.setPref('UIBLeaveCompletedInPlace', '1', 'true');
    }
  );
  $('body').on(
    'click',
    '#preferences #UIBLeaveCompletedInPlace-no',
    function () {
      NIRV.setPref('UIBLeaveCompletedInPlace', '0', 'true');
    }
  );

  // LOOK & FEEL

  // Appearance - light | dark | auto
  $('body').on('change', '#preferences #UIBAppearance', function (e) {
    NIRV.setPref('UIBAppearance', e.target.value, 'true');
  });

  // Color Theme - blue | pink | orange ...
  $('body').on('change', '#preferences #UIBThemeName', function (e) {
    NIRV.setPref('UIBThemeName', e.target.value, 'true');
  });

  // Background - light (snow) | dark (slate)
  $('body').on('change', '#preferences #UIBThemeBackground', function (e) {
    NIRV.setPref('UIBThemeBackground', e.target.value, 'true');
  });

  // Density - comfortable | compact
  $('body').on('change', '#preferences #UIBDensity', function (e) {
    NIRV.setPref('UIBDensity', e.target.value, 'true');
  });

  // Font Family - proxima-nova | trebuchet
  $('body').on('change', '#preferences #UIB3FontFamily', function () {
    NIRV.setPref('UIB3FontFamily', $(this).val(), 'true');
  });

  // DATE FORMAT
  $('body').on('change', '#preferences #UIDateLocale', function () {
    NIRV.setPref('UIDateLocale', $(this).val(), 'true');
    NIRV.appSettings();
  });

  // CALENDAR WEEK
  $('body').on('click', '#preferences #UIDateWeekStartsOnSun', function () {
    NIRV.setPref('UIDateWeekStartsOn', '0', 'true');
  });
  $('body').on('click', '#preferences #UIDateWeekStartsOnMon', function () {
    NIRV.setPref('UIDateWeekStartsOn', '1', 'true');
  });

  // DUE DATES
  $('body').on('click', '#preferences #UIRelativeDueDates-enable', function () {
    NIRV.setPref('UIRelativeDueDates', '1', 'true');
  });
  $('body').on(
    'click',
    '#preferences #UIRelativeDueDates-disable',
    function () {
      NIRV.setPref('UIRelativeDueDates', '0', 'true');
    }
  );
});
