// LISTERNERS EAST

$(function () {
  $('body').on(NIRV.mousedown, 'a.view.inbox', function (e) {
    if (location.hash == '#inbox') {
      NIRV.view('inbox');
    } else {
      location.hash = '#inbox';
    }
  });

  $('body').on(NIRV.mousedown, 'a.view.next', function (e) {
    if (e.which != 3) {
      if (location.hash == '#next') {
        NIRV.view('next');
      } else {
        location.hash = '#next';
      }
    }
  });

  $('body').on(NIRV.mousedown, 'a.view.later', function (e) {
    if (location.hash == '#later') {
      NIRV.view('later');
    } else {
      location.hash = '#later';
    }
  });

  $('body').on(NIRV.mousedown, 'a.view.waiting', function (e) {
    if (location.hash == '#waiting') {
      NIRV.view('waiting');
    } else {
      location.hash = '#waiting';
    }
  });

  $('body').on(NIRV.mousedown, 'a.view.scheduled', function (e) {
    if (location.hash == '#scheduled') {
      NIRV.view('scheduled');
    } else {
      location.hash = '#scheduled';
    }
  });

  $('body').on(NIRV.mousedown, 'a.view.someday', function (e) {
    if (location.hash == '#someday') {
      NIRV.view('someday');
    } else {
      location.hash = '#someday';
    }
  });

  $('body').on(NIRV.mousedown, 'a.view.focus', function (e) {
    if (e.which != 3) {
      if (location.hash == '#focus') {
        NIRV.view('focus');
      } else {
        location.hash = '#focus';
      }
    }
  });

  $('body').on(NIRV.mousedown, '#east h3.projects', function (e) {
    $h3 = $(this).closest('h3');
    if (
      e.which != 3 &&
      !$(e.target).hasClass('toggle') &&
      !$h3.hasClass('on')
    ) {
      if (location.hash == '#projects') {
        NIRV.view('projects');
      } else {
        location.hash = '#projects';
      }
    }
  });

  $('body').on(NIRV.mousedown, 'a.view.project', function (e) {
    if (e.which != 3) {
      if (location.hash == '#project/' + $(this).attr('pid')) {
        NIRV.viewproject($(this).attr('pid'));
      } else {
        location.hash = '#project/' + $(this).attr('pid');
      }
    }
  });

  $('body').on(NIRV.mousedown, '#east h3.reflists', function (e) {
    $h3 = $(this).closest('h3');
    if (
      e.which != 3 &&
      !$(e.target).hasClass('toggle') &&
      !$h3.hasClass('on')
    ) {
      if (location.hash == '#reflists') {
        NIRV.view('reflists');
      } else {
        location.hash = '#reflists';
      }
    }
  });

  $('body').on(NIRV.mousedown, 'a.view.reflist', function (e) {
    if (e.which != 3) {
      if (location.hash == '#reflist/' + $(this).attr('lid')) {
        NIRV.viewreflist($(this).attr('lid'));
      } else {
        location.hash = '#reflist/' + $(this).attr('lid');
      }
    }
  });

  $('body').on(NIRV.mousedown, 'a.view.logbook', function (e) {
    if (e.which != 3) {
      if (location.hash == '#logbook') {
        NIRV.view('logbook');
      } else {
        location.hash = '#logbook';
      }
    }
  });

  $('body').on(NIRV.mousedown, 'a.view.trash', function (e) {
    if (e.which != 3) {
      if (location.hash == '#trash') {
        NIRV.view('trash');
      } else {
        location.hash = '#trash';
      }
    }
  });

  $('body').on(NIRV.mousedown, '#east span.toggle', function (e) {
    $(this).parent().toggleClass('collapsed');
    var rel = $(this).parent().attr('rel');
    if ($(this).parent().hasClass('collapsed')) {
      NIRV.setPref($(this).parent().attr('pref'), '1', false);
      $(this).parent().next().hide();
      NIRV.collapse[rel] = true;
    } else {
      NIRV.setPref($(this).parent().attr('pref'), '0', false);
      $(this).parent().next().show();
      NIRV.collapse[rel] = false;
    }
    NIRV.reflow();
  });

  $('body').on(NIRV.mousedown, '#east h3 .name', function (e) {
    var $h3 = $(this).closest('h3');
    if ($h3.hasClass('projects') && !$h3.hasClass('on')) {
      // $(this).closest('h3').find('.toggle').click();
    } else if ($h3.hasClass('reflists') && !$h3.hasClass('on')) {
      // $(this).closest('h3').find('.toggle').click();
    } else {
      $(this).closest('h3').find('.toggle').click();
    }
  });

  $('body').on(NIRV.mousedown, '#east a.toggleinactive', function (e) {
    $(this).toggleClass('collapsed');
    var rel = $(this).attr('rel');
    if ($(this).hasClass('collapsed')) {
      $('#east ul.projects').addClass('hideinactive');
      $('#east a.toggleinactive').html('show inactive projects');
      NIRV.collapse[rel] = true;
    } else {
      $('#east ul.projects').removeClass('hideinactive');
      $('#east a.toggleinactive').html('hide inactive projects');
      NIRV.collapse[rel] = false;
    }
    NIRV.reflow();
  });

  // AOR MENU
  NIRV.x_currentarea = function (e) {
    if ($('#cmenu').offset().top > 0) {
      $('html').trigger('click');
    } else {
      var html = NIRV.renderAreaMenu();
      $('#cmenu').html(html);
      $('#cmenu').css(
        'top',
        $('.x_currentarea').offset().top - $(window).scrollTop() + 28
      );
      $('#cmenu').css(
        'left',
        $('.x_currentarea').offset().left + $('.x_currentarea').width()
      );
      $('#cmenu').css('z-index', 2000);
    }
  };

  NIRV.renderAreaMenu = function () {
    var areas = NIRV.areas();
    var x_selected = function (value) {
      return NIRV.currentarea == value ? 'x' : '';
    };
    var html = '';
    html += '<ul class="sf-menu areamenu">';
    html += '   <li>';
    html += '      <a href="#" class="cmenu submenu">&nbsp;</a>';
    html += '      <ul>';
    html +=
      '         <li><span class="label">Filter by Area Globally</span></li>';
    html +=
      '         <li><a class="' +
      x_selected('__ALL__') +
      '" href="#" fn="NIRV.setCurrentarea" p1="__ALL__" rel="Show All">All Areas<span class="hotkey shift">Shift <span class="pluschar">+ 0</span></span></a></li>';
    html += '         <li><span class="divider"></span></li>';
    if (areas.length != 0) {
      for (var i = 0; i < areas.length; i++) {
        html +=
          '<li><a class="' +
          x_selected(areas[i]) +
          '" href="#" fn="NIRV.setCurrentarea" p1="' +
          areas[i] +
          '">' +
          areas[i];
        if (i < 8) {
          html +=
            '<span class="hotkey shift">Shift <span class="pluschar">+ ' +
            (i + 1) +
            '</span></span>';
        }
        html += '</a></li>';
      }
      html += '         <li><span class="divider"></span></li>';
      html +=
        '         <li><a class="' +
        x_selected('__NONE__') +
        '" href="#" fn="NIRV.setCurrentarea" p1="__NONE__" rel="Unassigned">Unassigned<span class="hotkey shift">Shift <span class="pluschar">+ 9</span></span></a></li>';
      html += '         <li><span class="divider"></span></li>';
    }
    html +=
      '         <li><a href="#" fn="NIRV.manageAreas">Manage Areas...<span class="hotkey">G</span></a></li>';
    html += '      </ul>';
    html += '   </li>';
    html += '</ul>';

    return html;
  };

  NIRV.setCurrentarea = function (area) {
    NIRV.currentarea = area;
    NIRV.storage.setItem('NIRV.currentarea', NIRV.currentarea);

    $('#main').css('visibility', 'hidden');
    if (area == '__ALL__') {
      NIRV.refreshNorth();
      NIRV.refreshEast();
      NIRV.reflow();
      NIRV.refreshCurrentview();
    } else if (NIRV.currentview[0] == 'g') {
      // tag
      if (
        NIRV.currentview == 'g__ALL__' ||
        NIRV.cloudtags.indexOf(area) != -1 ||
        NIRV.currentview == 'g__NONE__'
      ) {
        NIRV.refreshNorth();
        NIRV.refreshEast();
        NIRV.reflow();
        NIRV.refreshCurrentview();
      } else {
        NIRV.refreshNorth();
        NIRV.refreshEast();
        NIRV.reflow();
        NIRV.view('focus');
      }
    } else if (NIRV.currentview[0] == 'p' && NIRV.currentview != 'projects') {
      // project
      NIRV.refreshNorth();
      NIRV.refreshEast();
      NIRV.reflow();
      NIRV.view('focus');
    } else if (
      NIRV.currentview[0] == 'l' &&
      NIRV.currentview != 'later' &&
      NIRV.currentview != 'logbook'
    ) {
      // reflist
      NIRV.refreshNorth();
      NIRV.refreshEast();
      NIRV.reflow();
      NIRV.view('focus');
    } else {
      NIRV.refreshNorth();
      NIRV.refreshEast();
      NIRV.reflow();
      NIRV.refreshCurrentview();
    }
    $('#main').css('visibility', 'visible');
  };

  // http://codereview.stackexchange.com/questions/13338/hasscroll-function-checking-if-a-scrollbar-is-visible-in-an-element
  function hasScroll(el, index, match) {
    var $el = $(el),
      sX = $el.css('overflow-x'),
      sY = $el.css('overflow-y'),
      hidden = 'hidden', // minifiers would like this better
      visible = 'visible',
      scroll = 'scroll',
      axis = match[3]; // regex for filter -> 3 == args to selector

    if (!axis) {
      // better check than undefined
      //Check both x and y declarations
      if (sX === sY && (sY === hidden || sY === visible)) {
        //same check but shorter syntax
        return false;
      }
      if (sX === scroll || sY === scroll) {
        return true;
      }
    } else if (axis === 'x') {
      // don't mix ifs and switches on the same variable
      if (sX === hidden || sX === visible) {
        return false;
      }
      if (sX === scroll) {
        return true;
      }
    } else if (axis === 'y') {
      if (sY === hidden || sY === visible) {
        return false;
      }
      if (sY === scroll) {
        return true;
      }
    }

    //Compare client and scroll dimensions to see if a scrollbar is needed

    return (
      $el.innerHeight() < el.scrollHeight || //make use of potential short circuit
      $el.innerWidth() < el.scrollWidth
    ); //innerHeight is the one you want
  }
  $.expr[':'].hasScroll = hasScroll;
});
