// JQUERY MAGIC
$(function () {
  console.log('RUN');
  console.log(NIRV.app_info());

  NIRV.L = NIRV.langtokens['en'];
  NIRV.authtoken = NIRV.storage.getItem('authtoken') || '';

  if (NIRV.authtoken == '') {
    NIRV.goLogin();
  } else {
    NIRV.goApp();
  }

  $.expr[':'].econtains = function (obj, index, meta, stack) {
    return (obj.textContent || obj.innerText || $(obj).text() || '') == meta[3];
  };

  $.expr[':'].eicontains = function (obj, index, meta, stack) {
    return (
      (obj.textContent || obj.innerText || $(obj).text() || '').toLowerCase() ==
      meta[3].toLowerCase()
    );
  };

  $('body')
    .on('keypress', '.bit-input input, #taglib input', function (e) {
      if (e.which == 34) return false; // "
      if (e.which == 39) return false; // '
      if (e.which == 60) return false; // <
      if (e.which == 62) return false; // >
      if (e.which == 92) return false; // \
    })
    .on('keyup', '.bit-input input, #taglib input', function (e) {
      var v = $(this).val();
      var s = v.replace(/"/g, ' ');
      s = s.replace(/'/g, ' ');
      s = s.replace(/</g, '');
      s = s.replace(/>/g, '');
      s = s.replace(/\\/g, '');
      s = s.replace(/,/g, '');
      if (s != v) {
        $(this).val(s);
      }
    });
});

