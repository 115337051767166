// CONTEXTUAL MENUS

NIRV.coerceMenuIntoViewport = function (submenu) {
  submenu = submenu || $('#cmenu ul.sf-menu a.submenu:first');
  var ds = $(document).scrollTop();
  var wh = $(window).height();
  var wb = ds + wh;
  var ulo = $(submenu).next().offset();
  var ulh = $(submenu).next().height();
  var ulb = ulo != undefined ? ulo.top + ulh : ulh;
  if (ulb > wb) {
    var margintop = wb - ulb - 20;
    $(submenu).next().css('margin-top', margintop);
  }
};

NIRV.cmenuTaglib = function (e) {
  var key = $(e.target).closest('li').attr('key');
  if (NIRV.tags[key] != undefined) {
    var tag = NIRV.tags[key];
    var xType = function (val) {
      return val == tag.type ? 'x' : '';
    };
    var xColor = function (val) {
      return val == tag.color ? 'x' : '';
    };
    var html = '';
    html += '<ul class="sf-menu">';
    html += '   <li>';
    html += '       <a href="#" class="cmenu submenu">&nbsp;</a>';
    html += '       <ul>';
    html += '           <li><span class="label">Tag: ' + key + '</span></li>';
    html +=
      '           <li><a href="#" fn="NIRV.editTag" p1="' +
      key +
      '">Rename...</a></li>';
    html +=
      '           <li><a href="#" fn="NIRV.promptDeleteTag" p1="' +
      key +
      '">Delete...</a></li>';
    html += '           <li><span class="divider"></span></li>';
    html += '           <li><span class="label">Type</span></li>';
    if (NIRV.user.maxareas > NIRV.areaCount()) {
      html +=
        '           <li><a href="#" class="toggle ' +
        xType(NIRV.CONST.AREA) +
        '" fn="NIRV.setTagType" p1="' +
        key +
        '" p2="' +
        NIRV.CONST.AREA +
        '">' +
        NIRV.L.area +
        '</a></li>';
    }
    html +=
      '           <li><a href="#" class="toggle ' +
      xType(NIRV.CONST.CONTACT) +
      '" fn="NIRV.setTagType" p1="' +
      key +
      '" p2="' +
      NIRV.CONST.CONTACT +
      '">' +
      NIRV.L.contact +
      '</a></li>';
    html +=
      '           <li><a href="#" class="toggle ' +
      xType(NIRV.CONST.CONTEXT) +
      '" fn="NIRV.setTagType" p1="' +
      key +
      '" p2="' +
      NIRV.CONST.CONTEXT +
      '">Label</a></li>';
    html += '           <li><span class="divider"></span></li>';
    html += '           <li><span class="label">Customize</span></li>';
    html += '           <li><a href="#" class="submenu">Color</a>';
    html += '               <ul style="width:100px;">';
    html += '                  <li><span class="label">Color</span></li>';
    html +=
      '                  <li class="color"><a href="#" class="color_none ' +
      xColor('') +
      '" fn="NIRV.setTagColor" p1="' +
      key +
      '" p2="">none</a></li>';
    html +=
      '                  <li class="color"><a href="#" class="color_red ' +
      xColor('red') +
      '" fn="NIRV.setTagColor" p1="' +
      key +
      '" p2="red"><span></span> red</a></li>';
    html +=
      '                  <li class="color"><a href="#" class="color_orange ' +
      xColor('orange') +
      '" fn="NIRV.setTagColor" p1="' +
      key +
      '" p2="orange"><span></span> orange</a></li>';
    html +=
      '                  <li class="color"><a href="#" class="color_yellow ' +
      xColor('yellow') +
      '" fn="NIRV.setTagColor" p1="' +
      key +
      '" p2="yellow"><span></span> yellow</a></li>';
    html +=
      '                  <li class="color"><a href="#" class="color_green ' +
      xColor('green') +
      '" fn="NIRV.setTagColor" p1="' +
      key +
      '" p2="green"><span></span> green</a></li>';
    html +=
      '                  <li class="color"><a href="#" class="color_blue ' +
      xColor('blue') +
      '" fn="NIRV.setTagColor" p1="' +
      key +
      '" p2="blue"><span></span> blue</a></li>';
    html +=
      '                  <li class="color"><a href="#" class="color_purple ' +
      xColor('purple') +
      '" fn="NIRV.setTagColor" p1="' +
      key +
      '" p2="purple"><span></span> purple</a></li>';
    html += '               </ul>';
    html += '           </li>';
    html += '       </ul>';
    html += '   </li>';
    html += '</ul>';
    $('#cmenu').html(html);
    $('#cmenu').css(
      'top',
      $(e.target).closest('li').offset().top - $(window).scrollTop()
    );
    $('#cmenu').css('left', e.clientX + 140);
    $('#cmenu').css('z-index', 2000);
    $('#cmenu').removeClass('openleft');
  }
};

NIRV.cmenuCbarTag = function (e) {
  if (
    $(e.target).hasClass('area') ||
    $(e.target).hasClass('context') ||
    $(e.target).hasClass('contact')
  ) {
    var key = $(e.target).text();
    if (NIRV.tags[key] != undefined) {
      var tag = NIRV.tags[key];
      var xType = function (val) {
        return val == tag.type ? 'x' : '';
      };
      var xColor = function (val) {
        return val == tag.color ? 'x' : '';
      };
      var html = '';
      html += '<ul class="sf-menu">';
      html += '   <li>';
      html += '       <a href="#" class="cmenu submenu">&nbsp;</a>';
      html += '       <ul class="cmenuCbarTag">';
      html += '           <li><span class="label">Type</span></li>';
      if (NIRV.user.maxareas > NIRV.areaCount()) {
        html +=
          '           <li><a href="#" class="' +
          xType(NIRV.CONST.AREA) +
          '" fn="NIRV.setTagType" p1="' +
          key +
          '" p2="' +
          NIRV.CONST.AREA +
          '">' +
          NIRV.L.area +
          '</a></li>';
      }
      html +=
        '           <li><a href="#" class="' +
        xType(NIRV.CONST.CONTEXT) +
        '" fn="NIRV.setTagType" p1="' +
        key +
        '" p2="' +
        NIRV.CONST.CONTEXT +
        '">Label</a></li>';
      html +=
        '           <li><a href="#" class="' +
        xType(NIRV.CONST.CONTACT) +
        '" fn="NIRV.setTagType" p1="' +
        key +
        '" p2="' +
        NIRV.CONST.CONTACT +
        '">' +
        NIRV.L.contact +
        '</a></li>';

      html += '           <li><span class="divider"></span></li>';
      html += '           <li><span class="label">Customize</span></li>';
      html += '           <li><a href="#" class="submenu">Color</a>';
      html += '               <ul style="width:100px;">';
      html += '                  <li><span class="label">Color</span></li>';
      html +=
        '                  <li class="color"><a href="#" class="color_none ' +
        xColor('') +
        '" fn="NIRV.setTagColor" p1="' +
        key +
        '" p2="">none</a></li>';
      html +=
        '                  <li class="color"><a href="#" class="color_red ' +
        xColor('red') +
        '" fn="NIRV.setTagColor" p1="' +
        key +
        '" p2="red"><span></span> red</a></li>';
      html +=
        '                  <li class="color"><a href="#" class="color_orange ' +
        xColor('orange') +
        '" fn="NIRV.setTagColor" p1="' +
        key +
        '" p2="orange"><span></span> orange</a></li>';
      html +=
        '                  <li class="color"><a href="#" class="color_yellow ' +
        xColor('yellow') +
        '" fn="NIRV.setTagColor" p1="' +
        key +
        '" p2="yellow"><span></span> yellow</a></li>';
      html +=
        '                  <li class="color"><a href="#" class="color_green ' +
        xColor('green') +
        '" fn="NIRV.setTagColor" p1="' +
        key +
        '" p2="green"><span></span> green</a></li>';
      html +=
        '                  <li class="color"><a href="#" class="color_blue ' +
        xColor('blue') +
        '" fn="NIRV.setTagColor" p1="' +
        key +
        '" p2="blue"><span></span> blue</a></li>';
      html +=
        '                  <li class="color"><a href="#" class="color_purple ' +
        xColor('purple') +
        '" fn="NIRV.setTagColor" p1="' +
        key +
        '" p2="purple"><span></span> purple</a></li>';
      html += '               </ul>';
      html += '           </li>';

      html += '       </ul>';
      html += '   </li>';
      html += '</ul>';
      $('#cmenu').html(html);
      $('#cmenu').css('top', $(e.target).position().top + 65);
      $('#cmenu').css('left', $(e.target).offset().left + 154);
      $('#cmenu').css('z-index', 2000);
      $('#cmenu').removeClass('openleft');
    }
  }
};

NIRV.cmenuMainTaskTag = function (e) {
  if (
    $(e.target).hasClass('area') ||
    $(e.target).hasClass('context') ||
    $(e.target).hasClass('contact')
  ) {
    var key = $(e.target).text();
    if (NIRV.tags[key] != undefined) {
      var tag = NIRV.tags[key];
      var xType = function (val) {
        return val == tag.type ? 'x' : '';
      };
      var xColor = function (val) {
        return val == tag.color ? 'x' : '';
      };
      var html = '';
      html += '<ul class="sf-menu">';
      html += '   <li>';
      // html += '       <a href="#" class="cmenu submenu">&nbsp;</a>';
      html += '       <ul class="cmenuCbarTag" style="width:100px;">';
      html += '           <li><span class="label">' + key + '</span></li>';
      html +=
        '           <li class="color"><a href="#" class="color_none ' +
        xColor('') +
        '" fn="NIRV.setTagColor" p1="' +
        key +
        '" p2="">none</a></li>';
      html +=
        '           <li class="color"><a href="#" class="color_red ' +
        xColor('red') +
        '" fn="NIRV.setTagColor" p1="' +
        key +
        '" p2="red"><span></span> red</a></li>';
      html +=
        '           <li class="color"><a href="#" class="color_orange ' +
        xColor('orange') +
        '" fn="NIRV.setTagColor" p1="' +
        key +
        '" p2="orange"><span></span> orange</a></li>';
      html +=
        '           <li class="color"><a href="#" class="color_yellow ' +
        xColor('yellow') +
        '" fn="NIRV.setTagColor" p1="' +
        key +
        '" p2="yellow"><span></span> yellow</a></li>';
      html +=
        '           <li class="color"><a href="#" class="color_green ' +
        xColor('green') +
        '" fn="NIRV.setTagColor" p1="' +
        key +
        '" p2="green"><span></span> green</a></li>';
      html +=
        '           <li class="color"><a href="#" class="color_blue ' +
        xColor('blue') +
        '" fn="NIRV.setTagColor" p1="' +
        key +
        '" p2="blue"><span></span> blue</a></li>';
      html +=
        '           <li class="color"><a href="#" class="color_purple ' +
        xColor('purple') +
        '" fn="NIRV.setTagColor" p1="' +
        key +
        '" p2="purple"><span></span> purple</a></li>';
      html += '       </ul>';
      html += '   </li>';
      html += '</ul>';
      $('#cmenu').html(html);
      $('#cmenu').css(
        'top',
        $(e.target).closest('li.task').offset().top - $(window).scrollTop() + 5
      );
      $('#cmenu').css(
        'left',
        $(e.target).closest('span.tag').offset().left + 42
      );
      $('#cmenu').css('z-index', 2000);
      if ($(window).width() - NIRV.mouseX < 340) {
        $('#cmenu').addClass('openleft');
      } else {
        $('#cmenu').removeClass('openleft');
      }
    }
    NIRV.coerceMenuIntoViewport();
  }
};

NIRV.cmenuViewOptionsNext = function (e) {
  var x_pref = function (key, value) {
    return NIRV.prefs[key] != undefined && NIRV.prefs[key].value == value
      ? 'x'
      : '';
  };
  var html = '';
  html += '<ul class="sf-menu">';
  html += '   <li>';
  html += '       <a href="#" class="cmenu submenu">&nbsp;</a>';
  html += '       <ul>';
  html += '         <li><span class="label">Next View Options</span></li>';
  html +=
    '         <li><a href="#" class="toggle ' +
    x_pref('UIBNextGroupBy', 'none') +
    '" fn="NIRV.setPref" p1="UIBNextGroupBy" p2="none" p3="true">unified list</a></li>';
  html +=
    '         <li><a href="#" class="toggle ' +
    x_pref('UIBNextGroupBy', 'parentid') +
    '" fn="NIRV.setPref" p1="UIBNextGroupBy" p2="parentid" p3="true">group by project</a></li>';
  html +=
    '         <li><a href="#" class="toggle ' +
    x_pref('UIBNextGroupBy', '') +
    '" fn="NIRV.setPref" p1="UIBNextGroupBy" p2="" p3="true">sequential next actions</a></li>';
  if (x_pref('UIBNextGroupBy', 'parentid')) {
    html += '         <li><span class="divider"></span></li>';
    html += '         <li><a href="#" class="submenu">project actions</a>';
    html += '         <ul style="width:80px;">';
    html += '         <li><span class="label">Show</span></li>';
    html +=
      '         <li><a href="#" fn="NIRV.setPref" p1="UIBProjectNextActionsTopN" p2="1" p3="true" class="' +
      x_pref('UIBProjectNextActionsTopN', '1') +
      '">top 1</a></li>';
    html +=
      '         <li><a href="#" fn="NIRV.setPref" p1="UIBProjectNextActionsTopN" p2="2" p3="true" class="' +
      x_pref('UIBProjectNextActionsTopN', '2') +
      '">top 2</a></li>';
    html +=
      '         <li><a href="#" fn="NIRV.setPref" p1="UIBProjectNextActionsTopN" p2="3" p3="true" class="' +
      x_pref('UIBProjectNextActionsTopN', '3') +
      '">top 3</a></li>';
    html +=
      '         <li><a href="#" fn="NIRV.setPref" p1="UIBProjectNextActionsTopN" p2="4" p3="true" class="' +
      x_pref('UIBProjectNextActionsTopN', '4') +
      '">top 4</a></li>';
    html +=
      '         <li><a href="#" fn="NIRV.setPref" p1="UIBProjectNextActionsTopN" p2="5" p3="true" class="' +
      x_pref('UIBProjectNextActionsTopN', '5') +
      '">top 5</a></li>';
    html +=
      '         <li><a href="#" fn="NIRV.setPref" p1="UIBProjectNextActionsTopN" p2="6" p3="true" class="' +
      x_pref('UIBProjectNextActionsTopN', '6') +
      '">top 6</a></li>';
    html +=
      '         <li><a href="#" fn="NIRV.setPref" p1="UIBProjectNextActionsTopN" p2="7" p3="true" class="' +
      x_pref('UIBProjectNextActionsTopN', '7') +
      '">top 7</a></li>';
    html +=
      '         <li><a href="#" fn="NIRV.setPref" p1="UIBProjectNextActionsTopN" p2="8" p3="true" class="' +
      x_pref('UIBProjectNextActionsTopN', '8') +
      '">top 8</a></li>';
    html +=
      '         <li><a href="#" fn="NIRV.setPref" p1="UIBProjectNextActionsTopN" p2="9" p3="true" class="' +
      x_pref('UIBProjectNextActionsTopN', '9') +
      '">top 9</a></li>';
    html +=
      '         <li><a href="#" fn="NIRV.setPref" p1="UIBProjectNextActionsTopN" p2="9999" p3="true" class="' +
      x_pref('UIBProjectNextActionsTopN', '9999') +
      '">all</a></li>';
    html += '         </ul>';
    html += '         </li>';
  }
  html += '       </ul>';
  html += '   </li>';
  html += '</ul>';
  $('#cmenu').html(html);
  if ($('#cmenu').offset().top > 0) {
    $('html').trigger('click');
  } else {
    if ($(e.target).hasClass('options')) {
      $('#cmenu').css('top', $(e.target).position().top + 65);
      $('#cmenu').css('left', $(e.target).offset().left + 10);
      $('#cmenu').css('z-index', 2000);
    } else {
      $('#cmenu').css(
        'top',
        $(e.target).closest('li.next').offset().top - $(window).scrollTop()
      );
      $('#cmenu').css('left', e.clientX + 180);
      $('#cmenu').css('z-index', 2000);
    }
    $('#cmenu').removeClass('openleft'); // this menu is always on the right
  }
};

NIRV.cmenuViewOptionsFocus = function (e) {
  var x_pref = function (key, value) {
    return NIRV.prefs[key] != undefined && NIRV.prefs[key].value == value
      ? 'x'
      : '';
  };
  var html = '';
  html += '<ul class="sf-menu">';
  html += '   <li>';
  html += '       <a href="#" class="cmenu submenu">&nbsp;</a>';
  html += '       <ul>';
  html += '         <li><span class="label">Focus View Options</span></li>';
  html +=
    '         <li><a href="#" class="toggle ' +
    x_pref('UIBFocusGroupBy', '') +
    '" fn="NIRV.setPref" p1="UIBFocusGroupBy" p2="" p3="true">unified list</a></li>';
  html +=
    '         <li><a href="#" class="toggle ' +
    x_pref('UIBFocusGroupBy', 'parentid') +
    '" fn="NIRV.setPref" p1="UIBFocusGroupBy" p2="parentid" p3="true">group by project</a></li>';
  html +=
    '         <li><a href="#" class="toggle ' +
    x_pref('UIBFocusGroupBy', 'state') +
    '" fn="NIRV.setPref" p1="UIBFocusGroupBy" p2="state" p3="true">group by state</a></li>';
  html += '       </ul>';
  html += '   </li>';
  html += '</ul>';
  $('#cmenu').html(html);
  if ($('#cmenu').offset().top > 0) {
    $('html').trigger('click');
  } else {
    if ($(e.target).hasClass('options')) {
      $('#cmenu').css('top', $(e.target).position().top + 65);
      $('#cmenu').css('left', $(e.target).offset().left + 10);
      $('#cmenu').css('z-index', 2000);
    } else {
      $('#cmenu').css(
        'top',
        $(e.target).closest('li.focus').offset().top - $(window).scrollTop()
      );
      $('#cmenu').css('left', e.clientX + 180);
      $('#cmenu').css('z-index', 2000);
      $('#cmenu').removeClass('openleft');
    }
  }
};

NIRV.cmenuViewOptionsProjects = function (e) {
  var x_pref = function (key, value) {
    return NIRV.prefs[key] != undefined && NIRV.prefs[key].value == value
      ? 'x'
      : '';
  };
  var html = '';
  html += '<ul class="sf-menu">';
  html += '   <li>';
  html += '       <a href="#" class="cmenu submenu">&nbsp;</a>';
  html += '       <ul>';
  html += '         <li><span class="label">Sort Projects</span></li>';
  html +=
    '         <li><a href="#" class="' +
    x_pref('UISortProjects', 'alpha') +
    '" fn="NIRV.setPref" p1="UISortProjects" p2="alpha" p3="1">Alphabetically</a></li>';
  html +=
    '         <li><a href="#" class="' +
    x_pref('UISortProjects', 'manually') +
    '" fn="NIRV.setPref" p1="UISortProjects" p2="manually" p3="1">Manually</a></li>';
  html += '       </ul>';
  html += '   </li>';
  html += '</ul>';
  $('#cmenu').html(html);
  if ($('#cmenu').offset().top > 0) {
    $('html').trigger('click');
  } else {
    if ($(e.target).hasClass('options')) {
      $('#cmenu').css('top', $(e.target).position().top + 65);
      $('#cmenu').css('left', $(e.target).offset().left + 10);
      $('#cmenu').css('z-index', 2000);
    } else {
      $('#cmenu').css(
        'top',
        $(e.target).closest('.projects').offset().top - $(window).scrollTop()
      );
      $('#cmenu').css('left', e.clientX + 180);
      $('#cmenu').css('z-index', 2000);
      $('#cmenu').removeClass('openleft');
    }
  }
};

NIRV.cmenuViewOptionsReflists = function (e) {
  var x_pref = function (key, value) {
    return NIRV.prefs[key] != undefined && NIRV.prefs[key].value == value
      ? 'x'
      : '';
  };
  var html = '';
  html += '<ul class="sf-menu">';
  html += '   <li>';
  html += '       <a href="#" class="cmenu submenu">&nbsp;</a>';
  html += '       <ul>';
  html += '         <li><span class="label">Sort Reference Lists</span></li>';
  html +=
    '         <li><a href="#" class="' +
    x_pref('UISortReflists', 'alpha') +
    '" fn="NIRV.setPref" p1="UISortReflists" p2="alpha" p3="1">Alphabetically</a></li>';
  html +=
    '         <li><a href="#" class="' +
    x_pref('UISortReflists', 'manually') +
    '" fn="NIRV.setPref" p1="UISortReflists" p2="manually" p3="1">Manually</a></li>';
  html += '       </ul>';
  html += '   </li>';
  html += '</ul>';
  $('#cmenu').html(html);
  if ($('#cmenu').offset().top > 0) {
    $('html').trigger('click');
  } else {
    if ($(e.target).hasClass('options')) {
      $('#cmenu').css('top', $(e.target).position().top + 65);
      $('#cmenu').css('left', $(e.target).offset().left + 10);
      $('#cmenu').css('z-index', 2000);
    } else {
      $('#cmenu').css(
        'top',
        $(e.target).closest('.reflists').offset().top - $(window).scrollTop()
      );
      $('#cmenu').css('left', e.clientX + 180);
      $('#cmenu').css('z-index', 2000);
      $('#cmenu').removeClass('openleft');
    }
  }
};

NIRV.cmenuLogbook = function (e) {
  var html = '';
  html += '<ul class="sf-menu">';
  html += '   <li>';
  html += '       <a href="#" class="cmenu submenu">&nbsp;</a>';
  html += '       <ul>';
  html +=
    '           <li><span class="label">' + NIRV.L.logbook + '</span></li>';
  html +=
    '           <li><a href="#" fn="NIRV.collectCompleted" p1="1">Log Completed...</a></li>';
  html += '       </ul>';
  html += '   </li>';
  html += '</ul>';
  $('#cmenu').html(html);
  if ($('#cmenu').offset().top > 0) {
    $('html').trigger('click');
  } else {
    if ($(e.target).hasClass('options')) {
      $('#cmenu').css('top', $(e.target).position().top + 65);
      $('#cmenu').css('left', $(e.target).offset().left + 10);
      $('#cmenu').css('z-index', 2000);
    } else {
      $('#cmenu').css(
        'top',
        $(e.target).closest('li.logbook').offset().top - $(window).scrollTop()
      );
      $('#cmenu').css('left', e.clientX + 180);
      $('#cmenu').css('z-index', 2000);
      $('#cmenu').removeClass('openleft');
    }
  }
};

NIRV.cmenuTrash = function (e) {
  var html = '';
  html += '<ul class="sf-menu">';
  html += '   <li>';
  html += '       <a href="#" class="cmenu submenu">&nbsp;</a>';
  html += '       <ul>';
  html += '           <li><span class="label">' + NIRV.L.trash + '</span></li>';
  html +=
    '           <li><a href="#" fn="NIRV.emptyTrash">Empty Trash...</a></li>';
  html += '       </ul>';
  html += '   </li>';
  html += '</ul>';
  $('#cmenu').html(html);
  if ($('#cmenu').offset().top > 0) {
    $('html').trigger('click');
  } else {
    if ($(e.target).hasClass('options')) {
      $('#cmenu').css('top', $(e.target).position().top + 65);
      $('#cmenu').css('left', $(e.target).offset().left + 10);
      $('#cmenu').css('z-index', 2000);
    } else {
      $('#cmenu').css(
        'top',
        $(e.target).closest('li.trash').offset().top -
          $(window).scrollTop() -
          24
      );
      $('#cmenu').css('left', e.clientX + 180);
      $('#cmenu').css('z-index', 2000);
      $('#cmenu').removeClass('openleft');
    }
  }
};

NIRV.cmenuMultitask = function (e, taskids) {
  var tags, xy, p3;
  var count_tasks = 0;
  var count_projects = 0;
  var count_refitems = 0;
  var count_reflists = 0;
  var count_recurring = 0;
  var count_scheduled = 0;
  var count_logged = 0;
  var count_trashed = 0;
  var sum_etime = 0;
  var ataskids = taskids.split(',');

  var x_tag = function (tag) {
    var found = 0;
    for (var i = 0; i < ataskids.length; i++) {
      if (NIRV.tasks[ataskids[i]].tags.indexOf(',' + tag + ',') != -1) {
        found++;
      }
    }
    if (found == 0) {
      return '';
    } else if (found < ataskids.length) {
      return 'y';
    } else {
      return 'x';
    }
  };

  for (var i = 0; i < ataskids.length; i++) {
    if (NIRV.tasks[ataskids[i]].type == NIRV.CONST.TASK) {
      count_tasks++;
      sum_etime += parseInt(NIRV.tasks[ataskids[i]].etime);
    }
    if (NIRV.tasks[ataskids[i]].type == NIRV.CONST.PROJECT) {
      count_projects++;
    }
    if (NIRV.tasks[ataskids[i]].type == NIRV.CONST.REFITEM) {
      count_refitems++;
    }
    if (NIRV.tasks[ataskids[i]].type == NIRV.CONST.REFLIST) {
      count_reflists++;
    }
    if (NIRV.tasks[ataskids[i]].state == NIRV.CONST.SCHEDULED) {
      count_scheduled++;
    }
    if (NIRV.tasks[ataskids[i]].state == NIRV.CONST.RECURRING) {
      count_recurring++;
    }
    if (NIRV.tasks[ataskids[i]].state == NIRV.CONST.LOGGED) {
      count_logged++;
    }
    if (NIRV.tasks[ataskids[i]].state == NIRV.CONST.TRASHED) {
      count_trashed++;
    }
  }

  var html = '';
  html += '<ul class="sf-menu">';
  html += '   <li>';
  html += '       <a href="#" class="cmenu submenu">&nbsp;</a>';
  html += '       <ul>';
  html += '           <li>';
  html += '               <span class="label">';
  html += '               ' + ataskids.length + ' Items';
  if (sum_etime > 0) {
    html += '               &nbsp;~ ' + etimehumansum(sum_etime) + ' total';
  }
  html += '           </span>';
  html += '           </li>';

  // date related
  if (
    count_refitems +
      count_reflists +
      count_recurring +
      count_logged +
      count_trashed ==
    0
  ) {
    // edit due date
    html +=
      '       <li><a href="#" fn="NIRV.promptDate" p1="' +
      taskids +
      '" p2="duedate" p3="view">' +
      NIRV.L.duedate +
      '...</a></li>';
    html += '       <li><span class="divider"></span></li>';
  }

  // areas
  html += '                   <li>';
  html += '                       <a href="#" class="submenu">Areas</a>';
  html += '                       <ul>';
  html +=
    '                           <li><span class="label">Areas</span></li>';
  tags = NIRV.areas();
  for (var i = 0; i < tags.length; i++) {
    xy = x_tag(tags[i]);
    p3 = xy == 'y' ? 'on' : '';
    html +=
      '                       <li><a href="#" class="toggle ' +
      xy +
      '" fn="NIRV.toggleTag" p1="' +
      taskids +
      '" p2="[text]" p3="' +
      p3 +
      '">' +
      tags[i] +
      '</a></li>';
  }
  html += '                      </ul>';
  html += '                   </li>';

  // contacts
  html += '                   <li>';
  html += '                       <a href="#" class="submenu">Contacts</a>';
  html += '                       <ul class="tall">';
  html +=
    '                           <li><span class="label">Contacts</span></li>';
  tags = NIRV.contacts();
  for (var i = 0; i < tags.length; i++) {
    xy = x_tag(tags[i]);
    p3 = xy == 'y' ? 'on' : '';
    html +=
      '                       <li><a href="#" class="toggle ' +
      xy +
      '" fn="NIRV.toggleTag" p1="' +
      taskids +
      '" p2="[text]" p3="' +
      p3 +
      '">' +
      tags[i] +
      '</a></li>';
  }
  html += '                      </ul>';
  html += '                   </li>';

  // contexts
  html += '                   <li>';
  html += '                       <a href="#" class="submenu">Labels</a>';
  html += '                       <ul class="tall">';
  html +=
    '                           <li><span class="label">Labels</span></li>';
  tags = NIRV.contexts();
  for (var i = 0; i < tags.length; i++) {
    xy = x_tag(tags[i]);
    p3 = xy == 'y' ? 'on' : '';
    html +=
      '                       <li><a href="#" class="toggle ' +
      xy +
      '" fn="NIRV.toggleTag" p1="' +
      taskids +
      '" p2="[text]" p3="' +
      p3 +
      '">' +
      tags[i] +
      '</a></li>';
  }
  html += '                      </ul>';
  html += '                   </li>';

  // effort
  if (
    count_tasks > 0 &&
    count_projects + count_refitems + count_reflists == 0
  ) {
    html += '       <li><span class="divider"></span></li>';
    html += '       <li>';
    html += '           <a href="#" class="submenu">Time</a>';
    html += '           <ul>';
    html +=
      '               <li><span class="label">' + NIRV.L.time + '</span></li>';
    html +=
      '               <li><a href="#" class="toggle" fn="NIRV.setEtime" p1="' +
      taskids +
      '" p2="5">5 minutes</a></li>';
    html +=
      '               <li><a href="#" class="toggle" fn="NIRV.setEtime" p1="' +
      taskids +
      '" p2="10">10 minutes</a></li>';
    html +=
      '               <li><a href="#" class="toggle" fn="NIRV.setEtime" p1="' +
      taskids +
      '" p2="15">15 minutes</a></li>';
    html +=
      '               <li><a href="#" class="toggle" fn="NIRV.setEtime" p1="' +
      taskids +
      '" p2="30">30 minutes</a></li>';
    html +=
      '               <li><a href="#" class="toggle" fn="NIRV.setEtime" p1="' +
      taskids +
      '" p2="45">45 minutes</a></li>';
    html +=
      '               <li><a href="#" class="toggle" fn="NIRV.setEtime" p1="' +
      taskids +
      '" p2="60">1 hour</a></li>';
    html +=
      '               <li><a href="#" class="toggle" fn="NIRV.setEtime" p1="' +
      taskids +
      '" p2="120">2 hours</a></li>';
    html +=
      '               <li><a href="#" class="toggle" fn="NIRV.setEtime" p1="' +
      taskids +
      '" p2="180">3 hours</a></li>';
    html +=
      '               <li><a href="#" class="toggle" fn="NIRV.setEtime" p1="' +
      taskids +
      '" p2="240">4 hours</a></li>';
    html +=
      '               <li><a href="#" class="toggle" fn="NIRV.setEtime" p1="' +
      taskids +
      '" p2="360">6 hours</a></li>';
    html +=
      '               <li><a href="#" class="toggle" fn="NIRV.setEtime" p1="' +
      taskids +
      '" p2="480">8 hours</a></li>';
    html +=
      '               <li><a href="#" class="toggle" fn="NIRV.setEtime" p1="' +
      taskids +
      '" p2="600">whoa nelly!</a></li>';
    html += '               <li><span class="divider"></span></li>';
    html +=
      '               <li><a href="#" class="" fn="NIRV.setEtime" p1="' +
      taskids +
      '" p2="0">none</a></li>';
    html += '           </ul>';
    html += '       </li>';
    html += '       <li>';
    html += '           <a href="#" class="submenu">Energy</a>';
    html += '           <ul>';
    html +=
      '               <li><span class="label">' +
      NIRV.L.energy +
      '</span></li>';
    html +=
      '               <li><a href="#" class="toggle" fn="NIRV.setEnergy" p1="' +
      taskids +
      '" p2="1">&nbsp;• low</a></li>';
    html +=
      '               <li><a href="#" class="toggle" fn="NIRV.setEnergy" p1="' +
      taskids +
      '" p2="2">&nbsp;•• med</a></li>';
    html +=
      '               <li><a href="#" class="toggle" fn="NIRV.setEnergy" p1="' +
      taskids +
      '" p2="3">&nbsp;••• high</a></li>';
    html += '               <li><span class="divider"></span></li>';
    html +=
      '               <li><a href="#" class="" fn="NIRV.setEnergy" p1="' +
      taskids +
      '" p2="0">none</a></li>';
    html += '           </ul>';
    html += '       </li>';
  }

  // ACTION STATE
  if (
    count_refitems +
      count_reflists +
      count_projects +
      count_logged +
      count_trashed ==
    0
  ) {
    html += '           <li><span class="divider"></span></li>';
    html += '           <li>';
    html += '               <a href="#" class="submenu">Action State</a>';
    html += '               <ul>';
    html += '                   <li><span class="label">Mark As</span></li>';
    html +=
      '                   <li><a href="#" class="toggle" fn="NIRV.dropTasksOnList" p1="' +
      taskids +
      '" p2="next">Next</a></li>';
    if (NIRV.prefs.UIEnableLater && NIRV.prefs.UIEnableLater.value == 1) {
      html +=
        '                   <li><a href="#" class="" fn="NIRV.dropTasksOnList" p1="' +
        taskids +
        '" p2="later">' +
        NIRV.L.later +
        '</a></li>';
    }
    html +=
      '                   <li><a href="#" class="toggle" fn="NIRV.dropTasksOnList" p1="' +
      taskids +
      '" p2="waiting">' +
      NIRV.L.waiting +
      '</a></li>';
    html +=
      '                   <li><a href="#" class="toggle" fn="NIRV.promptDate" p1="' +
      taskids +
      '" p2="startdate" p3="view">' +
      NIRV.L.scheduled +
      '</a></li>';
    html +=
      '                   <li><a href="#" class="toggle" fn="NIRV.dropTasksOnList" p1="' +
      taskids +
      '" p2="someday">' +
      NIRV.L.someday +
      '</a></li>';
    html += '               </ul>';
    html += '           </li>';
  }

  // PROJECT STATE
  if (count_refitems + count_reflists + count_tasks + count_logged == 0) {
    html += '       <li><span class="divider"></span></li>';
    html += '           <li>';
    html += '               <a href="#" class="submenu">Project State</a>';
    html += '               <ul>';
    html += '                   <li><span class="label">Mark As</span></li>';
    html +=
      '                   <li><a href="#" class="toggle" fn="NIRV.dropTasksOnList" p1="' +
      taskids +
      '" p2="next">Active Project</a></li>';
    if (NIRV.prefs.UIEnableLater && NIRV.prefs.UIEnableLater.value == 1) {
      html +=
        '                   <li><a href="#" class="toggle" fn="NIRV.dropTasksOnList" p1="' +
        taskids +
        '" p2="later">' +
        NIRV.L.later +
        '</a></li>';
    }
    html +=
      '                   <li><a href="#" class="toggle" fn="NIRV.promptDate" p1="' +
      taskids +
      '" p2="startdate" p3="view">' +
      NIRV.L.scheduled +
      '</a></li>';
    html +=
      '                   <li><a href="#" class="toggle" fn="NIRV.dropTasksOnList" p1="' +
      taskids +
      '" p2="someday">' +
      NIRV.L.someday +
      '</a></li>';
    html += '               </ul>';
    html += '           </li>';
  }

  // BEHAVIOR
  if (
    count_projects > 0 &&
    count_tasks + count_refitems + count_reflists + count_logged == 0
  ) {
    html += '       <li>';
    html += '           <a href="#" class="submenu">Project Behavior</a>';
    html += '           <ul>';
    html +=
      '               <li><span class="label">Treat Next actions as...</span></li>';
    html +=
      '               <li><a href="#" class="" fn="NIRV.setPS" p1="' +
      taskids +
      '" p2="' +
      NIRV.CONST.SEQUENTIAL +
      '">Sequential</a></li>';
    html +=
      '               <li><a href="#" class="" fn="NIRV.setPS" p1="' +
      taskids +
      '" p2="' +
      NIRV.CONST.PARALLEL +
      '">Parallel</a></li>';
    html += '           </ul>';
    html += '       </li>';
  }

  // MOVE TO
  if (count_trashed == 0) {
    html += '           <li><span class="divider"></span></li>';
    html += '           <li>';
    html += '               <a href="#" class="submenu">Move</a>';
    html += '               <ul>';
    html +=
      '                   <li><span class="label">Move selected to &rarr;</span></li>';

    // logbook
    if (
      count_refitems +
        count_reflists +
        count_recurring +
        count_logged +
        count_trashed ==
      0
    ) {
      html +=
        '                   <li><a href="#" fn="NIRV.dropTasksOnList" p1="' +
        taskids +
        '" p2="logbook">' +
        NIRV.L.logbook +
        '</a></li>';
    }

    html +=
      '                   <li><a href="#" fn="NIRV.dropTasksOnList" p1="' +
      taskids +
      '" p2="trash">' +
      NIRV.L.trash +
      '</a></li>';
  }

  html += '               </ul>';
  html += '           </li>';

  html += '      </ul>';
  html += '   </li>';
  html += '</ul>';
  $('#cmenu').html(html);
  // $('#cmenu').css('top', $(e.target).offset().top - $(window).scrollTop());
  $('#cmenu').css('top', NIRV.mouseY - 5 - $(window).scrollTop());
  $('#cmenu').css('left', NIRV.mouseX + 2);
  $('#cmenu').css('z-index', 2000);
  if ($(window).width() - NIRV.mouseX < 340) {
    $('#cmenu').addClass('openleft');
  } else {
    $('#cmenu').removeClass('openleft');
  }

  NIRV.coerceMenuIntoViewport();
};

NIRV.cmenuTask = function (e) {
  if ($(e.target).closest('li.task').hasClass('ui-selected')) {
    var taskids = '';
    $('#main li.ui-selected').each(function () {
      taskids += ',' + $(this).attr('taskid');
    });
    taskids = taskids.substr(1);
    if ($('#main li.ui-selected').length > 1) {
      NIRV.cmenuMultitask(e, taskids);
      return false;
    }
  }

  var pid = $(e.target).closest('#east li.project').find('a[pid]').attr('pid');
  var lid = $(e.target).closest('#east li.reflist').find('a[lid]').attr('lid');

  if (pid != undefined) {
    taskid = pid;
  } else if (lid != undefined) {
    taskid = lid;
  } else {
    var taskid = $(e.target).closest('li.task').attr('taskid');
  }

  if (taskid != undefined) {
    var task = NIRV.tasks[taskid];
    var x_state = function (val) {
      return val == task.state ? 'x' : '';
    };
    var x_parentid = function (val) {
      return val == task.parentid ? 'x' : '';
    };
    var x_tag = function (val) {
      return task.__tags__().indexOf(',' + val + ',') != -1 ? 'x' : '';
    };
    var x_ps = function (val) {
      return val == task.ps ? 'x' : '';
    };
    var x_etime = function (val) {
      return val == task.etime ? 'x' : '';
    };
    var x_energy = function (val) {
      return val == task.energy ? 'x' : '';
    };
    var x_waitingfor = function (val) {
      return task.waitingfor.indexOf(val) != -1 ? 'x' : '';
    };

    var html = '';

    html += '<ul class="sf-menu" taskid="' + task.id + '">';
    html += '   <li>';
    html += '       <a href="#" class="cmenu submenu">&nbsp;</a>';
    html += '       <ul>';

    // task name
    if (task.id.length > 40) {
      html +=
        '       <li><span class="label">' +
        nescape(task.name) +
        '<br>~ ' +
        new Date(yyyymmdd2mmddyyyy(task.id.substr(37))).toString(
          'MMM d, yyyy'
        ) +
        '</span></li>';
    } else {
      html +=
        '       <li><span class="label">' + nescape(task.name) + '</span></li>';
    }

    // date related
    if (
      task.state != NIRV.CONST.LOGGED &&
      task.state != NIRV.CONST.TRASHED &&
      task.state != NIRV.CONST.REFERENCE
    ) {
      // edit due date
      if (task.state != NIRV.CONST.RECURRING) {
        html +=
          '           <li><a href="#" fn="NIRV.promptDate" p1="[taskid]" p2="duedate" p3="view">' +
          NIRV.L.duedate +
          '...</a></li>';
      }

      // edit start date
      if (task.state == NIRV.CONST.SCHEDULED) {
        html +=
          '           <li><a href="#" fn="NIRV.promptDate" p1="[taskid]" p2="startdate" p3="view">Reschedule...</a></li>';
      }

      // edit repeating
      if (task.type == NIRV.CONST.TASK && task.id.length > 40) {
        var rtaskid = task.id.slice(0, 36);
        if (NIRV.tasks[rtaskid] != undefined) {
          html +=
            '           <li><a href="#" fn="NIRV.promptRecurring" p1="' +
            rtaskid +
            '">Edit Repeating Rules...</a></li>';
        }
      }
      if (task.type == NIRV.CONST.TASK && task.state == NIRV.CONST.RECURRING) {
        html +=
          '           <li><a href="#" fn="NIRV.promptRecurring" p1="[taskid]">Edit Repeating Rules...</a></li>';
      }

      html += '           <li><span class="divider"></span></li>';
    }

    // AREAS
    html += '                   <li>';
    html += '                       <a href="#" class="submenu">Areas</a>';
    html += '                       <ul class="tall">';
    html +=
      '                           <li><span class="label">Areas</span></li>';
    var tags = NIRV.areas();
    for (var i = 0; i < tags.length; i++) {
      html +=
        '                       <li><a href="#" class="toggle ' +
        x_tag(tags[i]) +
        '" fn="NIRV.toggleTag" p1="[taskid]" p2="[text]">' +
        tags[i] +
        '</a></li>';
    }
    html += '                           <li><span class="divider"></span></li>';
    html +=
      '                           <li><a href="#" fn="NIRV.manageAreas">Manage Areas...</a></li>';
    html += '                      </ul>';
    html += '                   </li>';

    // CONTACTS
    html += '                   <li>';
    html += '                       <a href="#" class="submenu">Contacts</a>';
    html += '                       <ul class="tall">';
    html +=
      '                           <li><span class="label">Contacts</span></li>';
    var tags = NIRV.contacts();
    for (var i = 0; i < tags.length; i++) {
      html +=
        '                       <li><a href="#" class="toggle ' +
        x_tag(tags[i]) +
        '" fn="NIRV.toggleTag" p1="[taskid]" p2="[text]">' +
        tags[i] +
        '</a></li>';
    }
    html += '                           <li><span class="divider"></span></li>';
    html +=
      '                           <li><a href="#" fn="NIRV.manageContacts">Manage Contacts...</a></li>';
    html += '                      </ul>';
    html += '                   </li>';

    // CONTEXTS
    html += '                   <li>';
    html += '                       <a href="#" class="submenu">Labels</a>';
    html += '                       <ul class="tall">';
    html +=
      '                           <li><span class="label">Labels</span></li>';
    var tags = NIRV.contexts();
    for (var i = 0; i < tags.length; i++) {
      html +=
        '                       <li><a href="#" class="toggle ' +
        x_tag(tags[i]) +
        '" fn="NIRV.toggleTag" p1="[taskid]" p2="[text]">' +
        tags[i] +
        '</a></li>';
    }
    html += '                           <li><span class="divider"></span></li>';
    html +=
      '                           <li><a href="#" fn="NIRV.manageContexts">Manage Labels...</a></li>';
    html += '                      </ul>';
    html += '                   </li>';

    html += '       <li><span class="divider"></span></li>';

    if (task.type == NIRV.CONST.TASK) {
      // time
      html += '       <li>';
      html += '           <a href="#" class="submenu">Time</a>';
      html += '           <ul>';
      html +=
        '               <li><span class="label">' +
        NIRV.L.time +
        '</span></li>';
      html +=
        '               <li><a href="#" class="toggle ' +
        x_etime(5) +
        '" fn="NIRV.setEtime" p1="[taskid]" p2="5">5 minutes</a></li>';
      html +=
        '               <li><a href="#" class="toggle ' +
        x_etime(10) +
        '" fn="NIRV.setEtime" p1="[taskid]" p2="10">10 minutes</a></li>';
      html +=
        '               <li><a href="#" class="toggle ' +
        x_etime(15) +
        '" fn="NIRV.setEtime" p1="[taskid]" p2="15">15 minutes</a></li>';
      html +=
        '               <li><a href="#" class="toggle ' +
        x_etime(30) +
        '" fn="NIRV.setEtime" p1="[taskid]" p2="30">30 minutes</a></li>';
      html +=
        '               <li><a href="#" class="toggle ' +
        x_etime(45) +
        '" fn="NIRV.setEtime" p1="[taskid]" p2="45">45 minutes</a></li>';
      html +=
        '               <li><a href="#" class="toggle ' +
        x_etime(60) +
        '" fn="NIRV.setEtime" p1="[taskid]" p2="60">1 hour</a></li>';
      html +=
        '               <li><a href="#" class="toggle ' +
        x_etime(120) +
        '" fn="NIRV.setEtime" p1="[taskid]" p2="120">2 hours</a></li>';
      html +=
        '               <li><a href="#" class="toggle ' +
        x_etime(180) +
        '" fn="NIRV.setEtime" p1="[taskid]" p2="180">3 hours</a></li>';
      html +=
        '               <li><a href="#" class="toggle ' +
        x_etime(240) +
        '" fn="NIRV.setEtime" p1="[taskid]" p2="240">4 hours</a></li>';
      html +=
        '               <li><a href="#" class="toggle ' +
        x_etime(360) +
        '" fn="NIRV.setEtime" p1="[taskid]" p2="360">6 hours</a></li>';
      html +=
        '               <li><a href="#" class="toggle ' +
        x_etime(480) +
        '" fn="NIRV.setEtime" p1="[taskid]" p2="480">8 hours</a></li>';
      html +=
        '               <li><a href="#" class="toggle ' +
        x_etime(600) +
        '" fn="NIRV.setEtime" p1="[taskid]" p2="600">whoa nelly!</a></li>';
      html += '               <li><span class="divider"></span></li>';
      html +=
        '               <li><a href="#" class="' +
        x_etime(0) +
        '" fn="NIRV.setEtime" p1="[taskid]" p2="0">none</a></li>';
      html += '           </ul>';
      html += '       </li>';

      // energy
      html += '       <li>';
      html += '           <a href="#" class="submenu">Energy</a>';
      html += '           <ul>';
      html +=
        '               <li><span class="label">' +
        NIRV.L.energy +
        '</span></li>';
      html +=
        '               <li><a href="#" class="toggle ' +
        x_energy(1) +
        '" fn="NIRV.setEnergy" p1="[taskid]" p2="1">&nbsp;• low</a></li>';
      html +=
        '               <li><a href="#" class="toggle ' +
        x_energy(2) +
        '" fn="NIRV.setEnergy" p1="[taskid]" p2="2">&nbsp;•• med</a></li>';
      html +=
        '               <li><a href="#" class="toggle ' +
        x_energy(3) +
        '" fn="NIRV.setEnergy" p1="[taskid]" p2="3">&nbsp;••• high</a></li>';
      html += '               <li><span class="divider"></span></li>';
      html +=
        '               <li><a href="#" class="' +
        x_energy(0) +
        '" fn="NIRV.setEnergy" p1="[taskid]" p2="0">none</a></li>';
      html += '           </ul>';
      html += '       </li>';

      html += '       <li><span class="divider"></span></li>';
    }

    // PROJECT STATE
    if (
      task.state != NIRV.CONST.LOGGED &&
      task.state != NIRV.CONST.TRASHED &&
      task.type == NIRV.CONST.PROJECT
    ) {
      html += '           <li>';
      html += '               <a href="#" class="submenu">Project State</a>';
      html += '               <ul>';
      html += '                   <li><span class="label">Mark As</span></li>';
      html +=
        '                   <li><a href="#" class="toggle ' +
        x_state(NIRV.CONST.ACTIVEPROJECT) +
        '" fn="NIRV.dropTasksOnList" p1="[taskid]" p2="next">Active Project</a></li>';
      html += '               <li><span class="divider"></span></li>';
      html += '               <li><span class="label">Inactive</span></li>';
      if (NIRV.prefs.UIEnableLater && NIRV.prefs.UIEnableLater.value == 1) {
        html +=
          '                   <li><a href="#" class="toggle ' +
          x_state(NIRV.CONST.LATER) +
          '" fn="NIRV.dropTasksOnList" p1="[taskid]" p2="later">' +
          NIRV.L.later +
          '</a></li>';
      }
      html +=
        '                   <li><a href="#" class="toggle ' +
        x_state(NIRV.CONST.SCHEDULED) +
        '" fn="NIRV.promptDate" p1="[taskid]" p2="startdate" p3="view">' +
        NIRV.L.scheduled +
        '</a></li>';
      html +=
        '                   <li><a href="#" class="toggle ' +
        x_state(NIRV.CONST.SOMEDAY) +
        '" fn="NIRV.dropTasksOnList" p1="[taskid]" p2="someday">' +
        NIRV.L.someday +
        '</a></li>';
      html += '               </ul>';
      html += '           </li>';

      // BEHAVIOR
      html += '           <li>';
      html += '               <a href="#" class="submenu">Project Behavior</a>';
      html += '               <ul>';
      html +=
        '                   <li><span class="label">Treat Next actions as</span></li>';
      // sequential
      html +=
        '                   <li><a href="#" class="' +
        x_ps(NIRV.CONST.SEQUENTIAL) +
        '" fn="NIRV.setPS" p1="[taskid]" p2="' +
        NIRV.CONST.SEQUENTIAL +
        '">Sequential</a></li>';
      // parallel
      html +=
        '                   <li><a href="#" class="' +
        x_ps(NIRV.CONST.PARALLEL) +
        '" fn="NIRV.setPS" p1="[taskid]" p2="' +
        NIRV.CONST.PARALLEL +
        '">Parallel</a></li>';
      html += '               </ul>';
      html += '           </li>';

      html += '       <li><span class="divider"></span></li>';
    }

    // ACTION STATE
    if (
      task.state != NIRV.CONST.LOGGED &&
      task.state != NIRV.CONST.TRASHED &&
      task.type == NIRV.CONST.TASK
    ) {
      html += '           <li>';
      html += '               <a href="#" class="submenu">Action State</a>';
      html += '               <ul>';
      html += '                   <li><span class="label">Mark As</span></li>';
      // next
      html +=
        '                   <li><a href="#" class="toggle ' +
        x_state(NIRV.CONST.NEXT) +
        '" fn="NIRV.dropTasksOnList" p1="[taskid]" p2="next">' +
        NIRV.L.next +
        '</a></li>';
      // later
      if (NIRV.prefs.UIEnableLater && NIRV.prefs.UIEnableLater.value == 1) {
        html +=
          '               <li><a href="#" class="toggle ' +
          x_state(NIRV.CONST.LATER) +
          '" fn="NIRV.dropTasksOnList" p1="[taskid]" p2="later">Later</a></li>';
      }
      // waiting
      html += '                   <li>';
      html +=
        '                       <a href="#" class="toggle submenu">Waiting</a>';
      html += '                       <ul class="tall">';
      html +=
        '                           <li><span class="label">Waiting For</span></li>';
      var tags = NIRV.contacts();
      for (var i = 0; i < tags.length; i++) {
        html +=
          '                       <li><a href="#" class="toggle ' +
          x_waitingfor(tags[i]) +
          '" fn="NIRV.setWaitingfor" p1="[taskid]" p2="[text]">' +
          tags[i] +
          '</a></li>';
      }
      html +=
        '                           <li><span class="divider"></span></li>';
      if (task.waitingfor != '') {
        html +=
          '                       <li><a href="#" class="' +
          x_state(NIRV.CONST.NEXT) +
          '" fn="NIRV.setState" p1="[taskid]" p2="' +
          NIRV.CONST.NEXT +
          '">No Longer Waiting</a></li>';
        html += '                       <li><span class="divider"></span></li>';
      }
      html +=
        '                           <li><a href="#" fn="NIRV.promptWaitingfor" p1="[taskid]">New Contact...</a></li>';
      html += '                       </ul>';
      html += '                   </li>';
      // scheduled
      html += '                   <li>';
      html +=
        '                       <a href="#" class="toggle submenu">' +
        NIRV.L.scheduled +
        '</a>';
      html += '                       <ul>';
      html +=
        '                           <li><span class="label">Schedule</span></li>';
      if (task.state == NIRV.CONST.RECURRING) {
        html +=
          '                       <li><a href="#" fn="NIRV.promptRecurring" p1="[taskid]">Edit Repeating Rules...</a></li>';
      } else {
        html +=
          '                       <li><a href="#" fn="NIRV.promptDate" p1="[taskid]" p2="startdate" p3="view">Start Date...</a></li>';
        if (task.id.length > 40) {
          var rtaskid = task.id.slice(0, 36);
          if (NIRV.tasks[rtaskid] != undefined) {
            html +=
              '           <li><a href="#" fn="NIRV.promptRecurring" p1="' +
              rtaskid +
              '">Edit Repeating Rules...</a></li>';
          }
        } else if (task.id.length < 40) {
          html +=
            '                   <li><a href="#" fn="NIRV.promptRecurring" p1="[taskid]">Make Repeating...</a></li>';
        }
      }
      html += '                       </ul>';
      html += '                   </li>';
      // someday
      html +=
        '                   <li><a href="#" class="toggle' +
        x_state(NIRV.CONST.SOMEDAY) +
        '" fn="NIRV.dropTasksOnList" p1="[taskid]" p2="someday">' +
        NIRV.L.someday +
        '</a></li>';
      html += '               </ul>';
      html += '           </li>';

      html += '       <li><span class="divider"></span></li>';
    }

    if (task.type == NIRV.CONST.TASK && task.state != NIRV.CONST.TRASHED) {
      // MOVE TO
      html += '           <li>';
      html += '               <a href="#" class="submenu">Move</a>';
      html += '               <ul>';
      html +=
        '                   <li><span class="label">Move Action to &rarr;</span></li>';

      if (task.state != NIRV.CONST.LOGGED && task.state != NIRV.CONST.TRASHED) {
        // inbox
        html +=
          '                   <li><a href="#" class="toggle ' +
          x_state(NIRV.CONST.INBOX) +
          '" fn="NIRV.dropTasksOnList" p1="[taskid]" p2="inbox">' +
          NIRV.L.inbox +
          '</a></li>';
        html += '                   <li><span class="divider"></span></li>';

        // projects
        var projects = NIRV.projects(NIRV.currentarea);
        var activeprojects = [];
        var inactiveprojects = [];
        for (var i = 0; i < projects.length; i++) {
          if (projects[i].state == NIRV.CONST.ACTIVEPROJECT) {
            activeprojects.push(projects[i]);
          } else if (projects[i].state != NIRV.CONST.LOGGED) {
            inactiveprojects.push(projects[i]);
          }
        }
        if (
          NIRV.prefs.UISortProjects &&
          NIRV.prefs.UISortProjects.value == 'alpha'
        ) {
          activeprojects = sortEntitiesBy(activeprojects, 'name', 'asc', false);
        }
        inactiveprojects = sortEntitiesBy(
          inactiveprojects,
          'name',
          'asc',
          false
        );
        if (activeprojects.length > 0) {
          html += '<li>';
          html += '<a href="#" class="submenu">Projects</a>';
          html += '<ul class="tall">';
          html += '<li><span class="label">Projects</span></li>';
          for (var i = 0; i < activeprojects.length; i++) {
            html +=
              '<li><a href="#" class="toggle ' +
              x_parentid(activeprojects[i].id) +
              '" fn="NIRV.setParentid" p1="[taskid]" p2="' +
              activeprojects[i].id +
              '">' +
              activeprojects[i].name +
              '</a></li>';
          }
          html += '</ul>';
          html += '</li>';
        }
        // INACTIVE PROJECTS
        if (inactiveprojects.length > 0) {
          // html += '               <li><span class="divider"></span></li>';
          html += '<li>';
          html += '<a href="#" class="submenu">Inactive Projects</a>';
          html += '<ul class="tall">';
          html += '<li><span class="label">Inactive Projects</span></li>';
          for (var i = 0; i < inactiveprojects.length; i++) {
            html +=
              '<li><a href="#" class="toggle ' +
              x_parentid(inactiveprojects[i].id) +
              '" fn="NIRV.setParentid" p1="[taskid]" p2="' +
              inactiveprojects[i].id +
              '">' +
              inactiveprojects[i].name +
              '</a></li>';
          }
          html += '</ul>';
          html += '</li>';
        }
        // REFLISTS
        // var reflists = NIRV.reflists(NIRV.currentarea);
        // if (NIRV.prefs.UISortReflists && NIRV.prefs.UISortReflists.value == 'alpha') {
        //     reflists = sortEntitiesBy(reflists, 'name', 'asc', false);
        // }
        // if (reflists.length > 0) {
        //     html += '<li>';
        //     html += '<a href="#" class="submenu">Reference</a>';
        //     html += '<ul class="tall">';
        //     html += '<li><span class="label">Reference Lists</span></li>';
        //     for (var i = 0; i < reflists.length; i++) {
        //         html += '<li><a href="#" class="toggle ' + x_parentid(reflists[i].id) + '" fn="NIRV.setParentid" p1="[taskid]" p2="' + reflists[i].id + '">' + reflists[i].name + '</a></li>';
        //     }
        //     html += '</ul>';
        //     html += '</li>';
        // }

        html += '<li><span class="divider"></span></li>';

        // logbook
        html +=
          '               <li><a href="#" class="toggle ' +
          x_state(NIRV.CONST.LOGGED) +
          '" fn="NIRV.dropTasksOnList" p1="[taskid]" p2="logbook">' +
          NIRV.L.logbook +
          '</a></li>';
      }

      if (task.state != NIRV.CONST.TRASHED) {
        // trash
        html +=
          '               <li><a href="#" class="toggle ' +
          x_state(NIRV.CONST.TRASHED) +
          '" fn="NIRV.dropTasksOnList" p1="[taskid]" p2="trash">' +
          NIRV.L.trash +
          '</a></li>';
      }
      html += '           </ul>';
      html += '       </li>';

      if (task.state != NIRV.CONST.LOGGED && task.state != NIRV.CONST.TRASHED) {
        // CONVERT TO
        html += '           <li>';
        html += '               <a href="#" class="submenu">Convert</a>';
        html += '               <ul>';
        html +=
          '                   <li><span class="label">Convert Action into a &rarr;</span></li>';

        if (task.parentid != '') {
          // action
          html +=
            '               <li><a href="#" fn="NIRV.setParentid" p1="[taskid]" p2="">Standalone Action</a></li>';
          // project
          html +=
            '               <li><a href="#" fn="NIRV.dropTasksOnList" p1="[taskid]" p2="projects">Standalone Project</a></li>';
          html += '               <li><span class="divider"></span></li>';
          // reference list
          html +=
            '               <li><a href="#" fn="NIRV.dropTasksOnList" p1="[taskid]" p2="reflists">Reference List</a></li>';
        } else {
          // project
          html +=
            '               <li><a href="#" fn="NIRV.dropTasksOnList" p1="[taskid]" p2="projects">Project</a></li>';
          html += '               <li><span class="divider"></span></li>';
          // reference list
          html +=
            '               <li><a href="#" fn="NIRV.dropTasksOnList" p1="[taskid]" p2="reflists">Reference List</a></li>';
        }
        html += '               <li>';

        // reference item
        html +=
          '                   <a href="#" class="submenu">Reference Item</a>';
        html += '                   <ul class="tall">';
        html +=
          '                       <li><span class="label">Inside</span></li>';
        var reflists = NIRV.reflists(NIRV.currentarea);
        if (
          NIRV.prefs.UISortReflists &&
          NIRV.prefs.UISortReflists.value == 'alpha'
        ) {
          reflists = sortEntitiesBy(reflists, 'name', 'asc', false);
        }
        if (reflists.length > 0) {
          for (var i = 0; i < reflists.length; i++) {
            html +=
              '           <li><a href="#" class="' +
              x_parentid(reflists[i].id) +
              '" fn="NIRV.setParentid" p1="[taskid]" p2="' +
              reflists[i].id +
              '">' +
              reflists[i].name +
              '</a></li>';
          }
        }
        html += '                   </ul>';
        html += '               </li>';
        html += '           </ul>';
        html += '       </li>';
      }
    }

    if (task.type == NIRV.CONST.PROJECT) {
      // MOVE TO
      html += '           <li>';
      html += '               <a href="#" class="submenu">Move</a>';
      html += '               <ul>';
      html +=
        '                   <li><span class="label">Move Project to &rarr;</span></li>';
      // logbook
      if (task.state != NIRV.CONST.LOGGED) {
        html +=
          '                   <li><a href="#" class="' +
          x_state(NIRV.CONST.LOGGED) +
          '" fn="NIRV.dropTasksOnList" p1="[taskid]" p2="logbook">' +
          NIRV.L.logbook +
          '</a></li>';
      }
      // trash
      html +=
        '                   <li><a href="#" class="' +
        x_state(NIRV.CONST.TRASHED) +
        '" fn="NIRV.dropTasksOnList" p1="[taskid]" p2="trash">' +
        NIRV.L.trash +
        '</a></li>';
      html += '               </ul>';
      html += '           </li>';

      if (task.state != NIRV.CONST.LOGGED) {
        // CONVERT TO
        html += '           <li>';
        html += '               <a href="#" class="submenu">Convert</a>';
        html += '               <ul>';
        html +=
          '                   <li><span class="label">Convert Project into a &rarr;</span></li>';
        // reference list
        html +=
          '                   <li><a href="#" fn="NIRV.dropTasksOnList" p1="[taskid]" p2="reflists">Reference List</a></li>';
        html += '               </ul>';
        html += '           </li>';
      }
    }

    if (task.type == NIRV.CONST.REFLIST) {
      // MOVE TO
      html += '           <li>';
      html += '               <a href="#" class="submenu">Move</a>';
      html += '               <ul>';
      html +=
        '                   <li><span class="label">Move List to &rarr;</span></li>';
      // trash
      html +=
        '                   <li><a href="#" class="' +
        x_state(NIRV.CONST.TRASHED) +
        '" fn="NIRV.dropTasksOnList" p1="[taskid]" p2="trash">' +
        NIRV.L.trash +
        '</a></li>';
      html += '               </ul>';
      html += '           </li>';

      // CONVERT TO
      html += '           <li>';
      html += '               <a href="#" class="submenu">Convert</a>';
      html += '               <ul>';
      html +=
        '                   <li><span class="label">Convert List into a &rarr;</span></li>';
      // reference list
      html +=
        '                   <li><a href="#" fn="NIRV.dropTasksOnList" p1="[taskid]" p2="projects">Project</a></li>';
      html += '               </ul>';
      html += '           </li>';
    }

    if (task.type == NIRV.CONST.REFITEM) {
      // MOVE TO
      html += '           <li>';
      html += '               <a href="#" class="submenu">Move</a>';
      html += '               <ul class="tall">';
      html +=
        '                   <li><span class="label">Move Item to &rarr;</span></li>';
      // inbox
      html +=
        '                   <li><a href="#" class="toggle ' +
        x_state(NIRV.CONST.INBOX) +
        '" fn="NIRV.dropTasksOnList" p1="[taskid]" p2="inbox">' +
        NIRV.L.inbox +
        '</a></li>';
      html += '                   <li><span class="divider"></span></li>';
      // reflists
      var reflists = NIRV.reflists(NIRV.currentarea);
      if (
        NIRV.prefs.UISortReflists &&
        NIRV.prefs.UISortReflists.value == 'alpha'
      ) {
        reflists = sortEntitiesBy(reflists, 'name', 'asc', false);
      }
      if (reflists.length > 0) {
        for (var i = 0; i < reflists.length; i++) {
          html +=
            '           <li><a href="#" class="toggle ' +
            x_parentid(reflists[i].id) +
            '" fn="NIRV.setParentid" p1="[taskid]" p2="' +
            reflists[i].id +
            '">' +
            reflists[i].name +
            '</a></li>';
        }
        html += '               <li><span class="divider"></span></li>';
      }
      // trash
      html +=
        '                   <li><a href="#" class="toggle ' +
        x_state(NIRV.CONST.TRASHED) +
        '" fn="NIRV.dropTasksOnList" p1="[taskid]" p2="trash">' +
        NIRV.L.trash +
        '</a></li>';
      html += '               </ul>';
      html += '           </li>';

      // CONVERT TO
      html += '           <li>';
      html += '               <a href="#" class="submenu">Convert</a>';
      html += '               <ul>';
      html +=
        '                   <li><span class="label">Convert Item into a &rarr;</span></li>';
      // action
      // html += '                   <li><a href="#" fn="NIRV.dropTasksOnList" p1="[taskid]" p2="inbox">Standalone Action (Inbox)</a></li>';
      // project
      html +=
        '                   <li><a href="#" fn="NIRV.dropTasksOnList" p1="[taskid]" p2="projects">Project</a></li>';
      // html += '                   <li><span class="divider"></span></li>';
      // reference list
      html +=
        '                   <li><a href="#" fn="NIRV.dropTasksOnList" p1="[taskid]" p2="reflists">Reference List</a></li>';
      html += '               </ul>';
      html += '           </li>';
    }

    if (task.state != NIRV.CONST.TRASHED) {
      html += '       <li><span class="divider"></span></li>';
    }

    if (
      task.state != NIRV.CONST.LOGGED &&
      task.state != NIRV.CONST.TRASHED &&
      task.type != NIRV.CONST.PROJECT
    ) {
      // make a copy
      html +=
        '       <li><a href="#" fn="NIRV.makeacopy" p1="[taskid]">Make a copy...</a></li>';
    }
    if (task.state == NIRV.CONST.LOGGED) {
      html += '       <li><span class="divider"></span></li>';
    }

    // email this
    html +=
      '       <li><a href="#" fn="NIRV.emailthis" p1="[taskid]" target="_blank">Email this...</a></li>';

    // html += '           <li><span class="info">created: '+timetoymd(task.created)+'</span></li>';
    // html += '           <li><span class="info">modified: '+timetoymd(task.updated)+'</span></li>';
    // html += '           <li><span class="info">'+task.id+'</span></li>';

    html += '      </ul>';
    html += '   </li>';
    html += '</ul>';

    $('#cmenu').html(html);

    // li.project (#east)
    if (pid != undefined) {
      $('#cmenu').css(
        'top',
        $(e.target).closest('li.project').offset().top -
          $(window).scrollTop() +
          2
      );
      $('#cmenu').css('left', e.clientX + 165);
    }
    // li.reflist (#east)
    else if (lid != undefined) {
      $('#cmenu').css(
        'top',
        $(e.target).closest('li.reflist').offset().top -
          $(window).scrollTop() +
          2
      );
      $('#cmenu').css('left', e.clientX + 165);
    }
    // li.task (#main, #cbar)
    else {
      $('#cmenu').css(
        'top',
        $(e.target).closest('li.task').offset().top - $(window).scrollTop() + 5
      );
      $('#cmenu').css('left', e.clientX + 2);
    }
    $('#cmenu').css('z-index', 2000);
    if ($(window).width() - NIRV.mouseX < 340) {
      $('#cmenu').addClass('openleft');
    } else {
      $('#cmenu').removeClass('openleft');
    }
  }
  NIRV.coerceMenuIntoViewport();
};

NIRV.cmenuParent = function (e) {
  var parentid = $(e.target)
    .closest('li.task.edit')
    .find('input[name=parentid]')
    .val();
  var x_parentid = function (val) {
    return val == parentid ? 'x' : '';
  };
  var html = '';
  html += '<ul class="sf-menu">';
  html += '   <li>';
  html += '       <a href="#" class="cmenu submenu">&nbsp;</a>';
  html += '       <ul>';
  html += '           <li><span class="label">Belongs to</span></li>';

  if (parentid != '') {
    html +=
      '               <li><a href="#" class="' +
      x_parentid('') +
      '" fn="NIRV.editParentid" p1="">None (' +
      NIRV.L.standalone +
      ')</a></li>';
    html += '               <li><span class="divider"></span></li>';
  }

  var projects = NIRV.projects(NIRV.currentarea);
  var activeprojects = [];
  var inactiveprojects = [];
  for (var i = 0; i < projects.length; i++) {
    if (projects[i].state == NIRV.CONST.ACTIVEPROJECT) {
      activeprojects.push(projects[i]);
    } else if (projects[i].state != NIRV.CONST.LOGGED) {
      inactiveprojects.push(projects[i]);
    }
  }
  if (NIRV.prefs.UISortProjects && NIRV.prefs.UISortProjects.value == 'alpha') {
    activeprojects = sortEntitiesBy(activeprojects, 'name', 'asc', false);
  }
  inactiveprojects = sortEntitiesBy(inactiveprojects, 'name', 'asc', false);

  if (activeprojects.length > 0) {
    html += '               <li>';
    html +=
      '                   <a href="#" class="submenu">Active Projects</a>';
    html += '                   <ul class="tall">';
    html +=
      '                       <li><span class="label">' +
      NIRV.L.activeprojects +
      '</span></li>';
    for (var i = 0; i < activeprojects.length; i++) {
      html +=
        '               <li><a href="#" class="' +
        x_parentid(activeprojects[i].id) +
        '" fn="NIRV.editParentid" p1="' +
        activeprojects[i].id +
        '">' +
        activeprojects[i].name +
        '</a></li>';
    }
    html += '                   </ul>';
    html += '               </li>';
  }
  if (inactiveprojects.length > 0) {
    html += '               <li>';
    html +=
      '                   <a href="#" class="submenu">Inactive Projects</a>';
    html += '                   <ul class="tall">';
    html +=
      '                       <li><span class="label">Inactive Projects</span></li>';
    for (var i = 0; i < inactiveprojects.length; i++) {
      html +=
        '               <li><a href="#" class="' +
        x_parentid(inactiveprojects[i].id) +
        '" fn="NIRV.editParentid" p1="' +
        inactiveprojects[i].id +
        '">' +
        inactiveprojects[i].name +
        '</a></li>';
    }
    html += '                   </ul>';
    html += '               </li>';
  }

  html += '      </ul>';
  html += '   </li>';
  html += '</ul>';
  $('#cmenu').html(html);
  $('#cmenu').css('top', $(e.target).offset().top - $(window).scrollTop());
  $('#cmenu').css('left', $(e.target).offset().left + 170);
  $('#cmenu').css('z-index', 2000);
  if ($(window).width() - NIRV.mouseX < 340) {
    $('#cmenu').addClass('openleft');
  } else {
    $('#cmenu').removeClass('openleft');
  }
  NIRV.coerceMenuIntoViewport();
};

NIRV.cmenuState = function (e) {
  var id = $(e.target).closest('li.task.edit').find('input[name=id]').val();
  var type = $(e.target).closest('li.task.edit').find('input[name=type]').val();
  var state = $(e.target)
    .closest('li.task.edit')
    .find('input[name=state]')
    .val();
  var recurring = $(e.target)
    .closest('li.task.edit')
    .find('input[name=recurring]')
    .val();
  var waitingfor = $(e.target)
    .closest('li.task.edit')
    .find('input[name=waitingfor]')
    .val();
  var x_state = function (val) {
    return val == state ? 'x' : '';
  };
  var x_waitingfor = function (val) {
    return waitingfor.indexOf(val) != -1 ? 'x' : '';
  };
  var html = '';
  html += '<ul class="sf-menu">';
  html += '   <li>';
  html += '       <a href="#" class="cmenu submenu">&nbsp;</a>';
  html += '          <ul>';
  if (type == NIRV.CONST.TASK) {
    if (state != NIRV.CONST.LOGGED && state != NIRV.CONST.TRASHED) {
      if (state == NIRV.CONST.SCHEDULED) {
        html += '           <li><span class="label">Reschedule</span></li>';
        html +=
          '           <li><a href="#" fn="NIRV.promptDate" p1="taskedit" p2="startdate" p3="edit">Start Date...</a></li>';
        if (id.length < 40) {
          html +=
            '           <li><a href="#" fn="NIRV.promptRecurring" p1="taskedit">Make Repeating...</a></li>';
        }
        html += '           <li><span class="divider"></span></li>';
      }
      if (state == NIRV.CONST.RECURRING) {
        html += '           <li><span class="label">Reschedule</span></li>';
        html +=
          '           <li><a href="#" fn="NIRV.promptRecurring" p1="taskedit">Edit Repeating Rules...</a></li>';
        html += '           <li><span class="divider"></span></li>';
      }
    }
    html += '           <li><span class="label">State</span></li>';
    if (state == NIRV.CONST.INBOX) {
      html +=
        '           <li><a href="#" class="' +
        x_state(NIRV.CONST.INBOX) +
        '" fn="NIRV.editState" p1="' +
        NIRV.CONST.INBOX +
        '">' +
        NIRV.L.inbox +
        '</a></li>';
      html += '           <li><span class="divider"></span></li>';
    }
    html +=
      '           <li><a href="#" class="' +
      x_state(NIRV.CONST.NEXT) +
      '" fn="NIRV.editState" p1="' +
      NIRV.CONST.NEXT +
      '">' +
      NIRV.L.next +
      '</a></li>';
    if (NIRV.prefs.UIEnableLater && NIRV.prefs.UIEnableLater.value == 1) {
      html +=
        '           <li><a href="#" class="' +
        x_state(NIRV.CONST.LATER) +
        '" fn="NIRV.editState" p1="' +
        NIRV.CONST.LATER +
        '">Later</a></li>';
    }
    html += '       <li>';
    html += '           <a href="#" class="submenu">' + NIRV.L.waiting + '</a>';
    html += '           <ul class="tall">';
    html +=
      '               <li><span class="label">' +
      NIRV.L.waitingfor +
      '</span></li>';
    var tags = NIRV.contacts();
    for (var i = 0; i < tags.length; i++) {
      html +=
        '           <li><a href="#" class="' +
        x_waitingfor(tags[i]) +
        '" fn="NIRV.editWaitingfor" p1="[text]">' +
        tags[i] +
        '</a></li>';
    }
    html += '               <li><span class="divider"></span></li>';
    if (waitingfor != '') {
      html +=
        '               <li><a href="#" class="" fn="NIRV.editState" p1="' +
        NIRV.CONST.NEXT +
        '">No Longer Waiting</a></li>';
      html += '               <li><span class="divider"></span></li>';
    }
    html +=
      '               <li><a href="#" fn="NIRV.promptWaitingfor" p1="taskedit">New Contact...</a></li>';
    html += '          </ul>';
    html += '       </li>';
    html += '       <li>';
    html +=
      '           <a href="#" class="submenu">' + NIRV.L.scheduled + '</a>';
    html += '           <ul>';
    html += '               <li><span class="label">Schedule For</span></li>';
    if (recurring == '') {
      html +=
        '               <li><a href="#" fn="NIRV.promptDate" p1="taskedit" p2="startdate" p3="edit">Start Date...</a></li>';
      if (id.length < 41) {
        html +=
          '               <li><a href="#" fn="NIRV.promptRecurring" p1="taskedit">Make Repeating...</a></li>';
      }
    } else {
      html +=
        '               <li><a href="#" fn="NIRV.promptRecurring" p1="taskedit">Edit Repeating Rules...</a></li>';
    }
    html += '          </ul>';
    html += '       </li>';
    html +=
      '           <li><a href="#" class="' +
      x_state(NIRV.CONST.SOMEDAY) +
      '" fn="NIRV.editState" p1="' +
      NIRV.CONST.SOMEDAY +
      '">' +
      NIRV.L.someday +
      '</a></li>';
  } else if (type == NIRV.CONST.PROJECT) {
    html += '           <li><span class="label">Mark As</span></li>';
    html +=
      '           <li><a href="#" class="toggle ' +
      x_state(NIRV.CONST.ACTIVEPROJECT) +
      '" fn="NIRV.editState" p1="' +
      NIRV.CONST.ACTIVEPROJECT +
      '">Active Project</a></li>';
    html += '               <li><span class="divider"></span></li>';
    html += '               <li><span class="label">Inactive</span></li>';
    if (NIRV.prefs.UIEnableLater && NIRV.prefs.UIEnableLater.value == 1) {
      html +=
        '           <li><a href="#" class="toggle ' +
        x_state(NIRV.CONST.LATER) +
        '" fn="NIRV.editState" p1="' +
        NIRV.CONST.LATER +
        '">' +
        NIRV.L.later +
        '</a></li>';
    }
    html +=
      '           <li><a href="#" class="toggle ' +
      x_state(NIRV.CONST.SCHEDULED) +
      '" fn="NIRV.promptDate" p1="taskedit" p2="startdate" p3="edit">' +
      NIRV.L.scheduled +
      '</a></li>';
    html +=
      '           <li><a href="#" class="toggle ' +
      x_state(NIRV.CONST.SOMEDAY) +
      '" fn="NIRV.editState" p1="' +
      NIRV.CONST.SOMEDAY +
      '">' +
      NIRV.L.someday +
      '</a></li>';
  }
  html += '      </ul>';
  html += '   </li>';
  html += '</ul>';
  $('#cmenu').html(html);
  $('#cmenu').css('top', $(e.target).offset().top - $(window).scrollTop());
  $('#cmenu').css('left', $(e.target).offset().left + 170);
  $('#cmenu').css('z-index', 2000);
  if ($(window).width() - NIRV.mouseX < 340) {
    $('#cmenu').addClass('openleft');
  } else {
    $('#cmenu').removeClass('openleft');
  }
};

NIRV.cmenuPS = function (e) {
  var ps = $(e.target).closest('li.task.edit').find('input[name=ps]').val();
  var x_ps = function (val) {
    return val == ps ? 'x' : '';
  };
  var html = '';
  html += '<ul class="sf-menu">';
  html += '   <li>';
  html += '       <a href="#" class="cmenu submenu">&nbsp;</a>';
  html += '       <ul>';
  html +=
    '           <li><span class="label">Treat Next actions as</span></li>';
  html +=
    '           <li><a href="#" class="' +
    x_ps(NIRV.CONST.PARALLEL) +
    '" fn="NIRV.editPS" p1="' +
    NIRV.CONST.PARALLEL +
    '">Parallel</a></li>';
  html +=
    '           <li><a href="#" class="' +
    x_ps(NIRV.CONST.SEQUENTIAL) +
    '" fn="NIRV.editPS" p1="' +
    NIRV.CONST.SEQUENTIAL +
    '">Sequential</a></li>';
  html += '      </ul>';
  html += '   </li>';
  html += '</ul>';
  $('#cmenu').html(html);
  $('#cmenu').css('top', $(e.target).offset().top - $(window).scrollTop());
  $('#cmenu').css('left', $(e.target).offset().left + 170);
  $('#cmenu').css('z-index', 2000);
  if ($(window).width() - NIRV.mouseX < 340) {
    $('#cmenu').addClass('openleft');
  } else {
    $('#cmenu').removeClass('openleft');
  }
};

NIRV.cmenuEtime = function (e) {
  var etime = $(e.target)
    .closest('li.task.edit')
    .find('input[name=etime]')
    .val();
  var x_etime = function (val) {
    return val == etime ? 'x' : '';
  };
  var html = '';
  html += '<ul class="sf-menu">';
  html += '   <li>';
  html += '       <a href="#" class="cmenu submenu">&nbsp;</a>';
  html += '       <ul>';
  html += '           <li><span class="label">' + NIRV.L.time + '</span></li>';
  html +=
    '           <li><a href="#" class="toggle ' +
    x_etime(5) +
    '" fn="NIRV.editEtime"p1="5">5 minutes</a></li>';
  html +=
    '           <li><a href="#" class="toggle ' +
    x_etime(10) +
    '" fn="NIRV.editEtime" p1="10">10 minutes</a></li>';
  html +=
    '           <li><a href="#" class="toggle ' +
    x_etime(15) +
    '" fn="NIRV.editEtime" p1="15">15 minutes</a></li>';
  html +=
    '           <li><a href="#" class="toggle ' +
    x_etime(30) +
    '" fn="NIRV.editEtime" p1="30">30 minutes</a></li>';
  html +=
    '           <li><a href="#" class="toggle ' +
    x_etime(45) +
    '" fn="NIRV.editEtime" p1="45">45 minutes</a></li>';
  html +=
    '           <li><a href="#" class="toggle ' +
    x_etime(60) +
    '" fn="NIRV.editEtime" p1="60">1 hour</a></li>';
  html +=
    '           <li><a href="#" class="toggle ' +
    x_etime(120) +
    '" fn="NIRV.editEtime" p1="120">2 hours</a></li>';
  html +=
    '           <li><a href="#" class="toggle ' +
    x_etime(180) +
    '" fn="NIRV.editEtime" p1="180">3 hours</a></li>';
  html +=
    '           <li><a href="#" class="toggle ' +
    x_etime(240) +
    '" fn="NIRV.editEtime" p1="240">4 hours</a></li>';
  html +=
    '           <li><a href="#" class="toggle ' +
    x_etime(360) +
    '" fn="NIRV.editEtime" p1="360">6 hours</a></li>';
  html +=
    '           <li><a href="#" class="toggle ' +
    x_etime(480) +
    '" fn="NIRV.editEtime" p1="480">8 hours</a></li>';
  html +=
    '           <li><a href="#" class="toggle ' +
    x_etime(600) +
    '" fn="NIRV.editEtime" p1="600">whoa nelly!</a></li>';
  html += '           <li><span class="divider"></span></li>';
  html +=
    '           <li><a href="#" class="' +
    x_etime(0) +
    '" fn="NIRV.editEtime" p1="0">none</a></li>';
  html += '      </ul>';
  html += '   </li>';
  html += '</ul>';
  $('#cmenu').html(html);
  $('#cmenu').css('top', $(e.target).offset().top - $(window).scrollTop());
  $('#cmenu').css('left', $(e.target).offset().left + 170);
  $('#cmenu').css('z-index', 2000);
  if ($(window).width() - NIRV.mouseX < 340) {
    $('#cmenu').addClass('openleft');
  } else {
    $('#cmenu').removeClass('openleft');
  }
};

NIRV.cmenuEnergy = function (e) {
  var energy = $(e.target)
    .closest('li.task.edit')
    .find('input[name=energy]')
    .val();
  var x_energy = function (val) {
    return val == energy ? 'x' : '';
  };
  var html = '';
  html += '<ul class="sf-menu">';
  html += '   <li>';
  html += '       <a href="#" class="cmenu submenu">&nbsp;</a>';
  html += '       <ul>';
  html +=
    '           <li><span class="label">' + NIRV.L.energy + '</span></li>';
  html +=
    '           <li><a href="#" class="toggle ' +
    x_energy(1) +
    '" fn="NIRV.editEnergy" p1="1">&nbsp;• low</a></li>';
  html +=
    '           <li><a href="#" class="toggle ' +
    x_energy(2) +
    '" fn="NIRV.editEnergy" p1="2">&nbsp;•• med</a></li>';
  html +=
    '           <li><a href="#" class="toggle ' +
    x_energy(3) +
    '" fn="NIRV.editEnergy" p1="3">&nbsp;••• high</a></li>';
  html += '           <li><span class="divider"></span></li>';
  html +=
    '           <li><a href="#" class="' +
    x_energy(0) +
    '" fn="NIRV.editEnergy" p1="0">none</a></li>';
  html += '      </ul>';
  html += '   </li>';
  html += '</ul>';
  $('#cmenu').html(html);
  $('#cmenu').css('top', $(e.target).offset().top - $(window).scrollTop());
  $('#cmenu').css('left', $(e.target).offset().left + 170);
  $('#cmenu').css('z-index', 2000);
  if ($(window).width() - NIRV.mouseX < 340) {
    $('#cmenu').addClass('openleft');
  } else {
    $('#cmenu').removeClass('openleft');
  }
};

NIRV.cmenuTags = function (e) {
  var edittags = [];
  $('li.task.edit ul.holder li.bit-box').each(function () {
    edittags.push($(this).attr('rel'));
  });
  var x_tag = function (val) {
    return edittags.indexOf(val) != -1 ? 'x' : '';
  };
  var html = '';
  html += '<ul class="sf-menu">';
  html += '   <li>';
  html += '       <a href="#" class="cmenu submenu">&nbsp;</a>';
  html += '       <ul>';
  html += '                   <li><span class="label">Tags</span></li>';
  // AREAS
  html += '                   <li>';
  html += '                       <a href="#" class="submenu">Areas</a>';
  html += '                       <ul class="tall">';
  html +=
    '                           <li><span class="label">Areas</span></li>';
  var tags = NIRV.areas();
  for (var i = 0; i < tags.length; i++) {
    html +=
      '                       <li><a href="#" class="toggle ' +
      x_tag(tags[i]) +
      '" fn="NIRV.editTags" p1="[text]">' +
      tags[i] +
      '</a></li>';
  }
  html += '                           <li><span class="divider"></span></li>';
  html +=
    '                           <li><a href="#" fn="NIRV.manageAreas">Manage Areas...</a></li>';
  html += '                      </ul>';
  html += '                   </li>';

  // CONTACTS
  html += '                   <li>';
  html += '                       <a href="#" class="submenu">Contacts</a>';
  html += '                       <ul class="tall">';
  html +=
    '                           <li><span class="label">Contacts</span></li>';
  var tags = NIRV.contacts();
  for (var i = 0; i < tags.length; i++) {
    html +=
      '                       <li><a href="#" class="toggle ' +
      x_tag(tags[i]) +
      '" fn="NIRV.editTags" p1="[text]">' +
      tags[i] +
      '</a></li>';
  }
  html += '                           <li><span class="divider"></span></li>';
  html +=
    '                           <li><a href="#" fn="NIRV.manageContacts">Manage Contacts...</a></li>';
  html += '                      </ul>';
  html += '                   </li>';
  // CONTEXTS (TAGS)
  html += '                   <li>';
  html += '                       <a href="#" class="submenu">Labels</a>';
  html += '                       <ul class="tall">';
  html +=
    '                           <li><span class="label">Labels</span></li>';
  var tags = NIRV.contexts();
  for (var i = 0; i < tags.length; i++) {
    html +=
      '                       <li><a href="#" class="toggle ' +
      x_tag(tags[i]) +
      '" fn="NIRV.editTags" p1="[text]">' +
      tags[i] +
      '</a></li>';
  }
  html += '                           <li><span class="divider"></span></li>';
  html +=
    '                           <li><a href="#" fn="NIRV.manageContexts">Manage Labels...</a></li>';
  html += '                      </ul>';
  html += '                   </li>';
  html += '      </ul>';
  html += '   </li>';
  html += '</ul>';
  $('#cmenu').html(html);
  $('#cmenu').css('top', $(e.target).offset().top - $(window).scrollTop());
  $('#cmenu').css('left', $(e.target).offset().left + 170);
  $('#cmenu').css('z-index', 2000);
  if ($(window).width() - NIRV.mouseX < 340) {
    $('#cmenu').addClass('openleft');
  } else {
    $('#cmenu').removeClass('openleft');
  }
};

NIRV.cmenuContacts = function (e) {
  var edittags = [];
  $('.pop_waitingfor ul.holder li.bit-box').each(function () {
    edittags.push($(this).attr('rel'));
  });
  var x_tag = function (val) {
    return edittags.indexOf(val) != -1 ? 'x' : '';
  };
  var html = '';
  html += '<ul class="sf-menu">';
  html += '   <li>';
  html += '                       <a href="#" class="submenu">Contacts</a>';
  html += '                       <ul class="tall">';
  html +=
    '                           <li><span class="label">Contacts</span></li>';
  var tags = NIRV.contacts();
  for (var i = 0; i < tags.length; i++) {
    html +=
      '                       <li><a href="#" class="toggle ' +
      x_tag(tags[i]) +
      '" fn="NIRV.setPromptWaitingfor" p1="[text]">' +
      tags[i] +
      '</a></li>';
  }
  // html += '                           <li><span class="divider"></span></li>';
  // html += '                           <li><a href="#" fn="NIRV.manageContacts">Manage Contacts...</a></li>';
  html += '                      </ul>';
  html += '                   </li>';
  html += '</ul>';
  $('#cmenu').html(html);
  $('#cmenu').css('top', $(e.target).offset().top - $(window).scrollTop());
  $('#cmenu').css('left', $(e.target).offset().left + 170);
  // $('ul.tall').css('height', '200px');
  $('#cmenu').css('z-index', 2000);
  if ($(window).width() - NIRV.mouseX < 340) {
    $('#cmenu').addClass('openleft');
  } else {
    $('#cmenu').removeClass('openleft');
  }
  NIRV.coerceMenuIntoViewport();
};

NIRV.toggleTag = function (taskid, tag, onoff) {
  onoff = onoff || 'flip';
  var ataskids = taskid.split(',');
  var commatagcomma = ',' + tag + ',';
  var task;
  for (var i = 0; i < ataskids.length; i++) {
    task = NIRV.tasks[ataskids[i]];
    if (onoff == 'on') {
      if (task.tags.indexOf(commatagcomma) == -1) {
        task.set('tags', task.tags + commatagcomma);
      }
    } else if (onoff == 'off') {
      task.set('tags', task.tags.replace(commatagcomma, ','));
    } else {
      if (task.tags.indexOf(commatagcomma) == -1) {
        task.set('tags', task.tags + commatagcomma);
      } else {
        task.set('tags', task.tags.replace(commatagcomma, ','));
      }
    }
  }
  // task.redraw();
  NIRV.save();

  NIRV.recalcWtasks();
  NIRV.refreshCbar();
  NIRV.refreshMain();
  NIRV.refreshEast();
  NIRV.reflow();
};
