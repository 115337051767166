NIRV.Append = {
  id: '',
  taskid: '',
  _taskid: 0,
  type: 1,
  _type: 0,
  meta: '',
  _meta: 0,
  deleted: 0,
  _deleted: 0,
  __stale__: false,
  set: function (key, value) {
    if (this[key] != value) {
      this[key] = value;
      if (key != undefined && key[0] != '_') {
        this['_' + key] = time(); // property updated at unixtime
      }
      this.__stale__ = true;
    }
    return this;
  },
  clone: function clone(init) {
    init = init || {};
    var o = cloneObject(this);
    for (var i in init) {
      o[i] = init[i];
    }
    return o;
  },
  save: function Append_save() {
    DEBUG && console.log('Append.save() ' + this.id);

    if (this.id == '') {
      return;
    }
    if (this.__stale__) {
      // this.cleanup(); // not implemented

      var data = {
        method: 'append.save',
        id: this.id,
        taskid: this.taskid,
        _taskid: this._taskid,
        type: this.type,
        _type: this._type,
        meta: this.meta,
        _meta: this._meta,
        deleted: this.deleted,
        _deleted: this._deleted,
      };

      var ldata = cloneObject(data);
      ldata.__stale__ = this.__stale__;
      delete ldata.method;
      NIRV.storage.setItem('append.' + ldata.id, JSON.stringify(ldata));

      return data;
    }
  },
  render: function Append_render() {
    var meta = JSON.parse(this.meta);
    var html = '';
    html +=
      '<div style="margin: 1px 0; padding: 5px; background: #eee; font-size: 12px;">' +
      '<a class="downloadFile" data-appendid="' +
      this.id +
      '" href="' +
      '#' +
      '">• ' +
      meta.filename +
      '</a> (' +
      bytesToHuman(meta.filesize) +
      ')' +
      '<span style="float: right;" class="removeFile" data-appendid="' +
      this.id +
      '">x</span>' +
      '</div>';
    return html;
  },
};
