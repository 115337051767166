NIRV.langtokens = {
   "en": {
      "inbox": "Inbox",
      "focus": "Focus",
      "next": "Next",
      "later": "Later",
      "waiting": "Waiting",
      "scheduled": "Scheduled",
      "someday": "Someday",
      "projects": "Projects",
      "reference": "Reference",
      "logbook": "Logbook",
      "trash": "Trash",
      "collect": "Collect",
      "todos": "To Do",
      "activeprojects": "Active Projects",
      "inactiveprojects": "Inactive Projects",
      "done": "Done",
      "all": "All",
      "none": "None",
      "save": "Save",
      "savechanges": "Save Changes",
      "ok": "OK",
      "cancel": "Cancel",
      "areyousure": "Are You Sure?",
      "effort": "Effort",
      "time": "Time",
      "minutes": "Minutes",
      "hours": "Hours",
      "hour": "Hour",
      "orless": "or less",
      "energy": "Energy",
      "low": "Low",
      "medium": "Medium",
      "high": "High",
      "scheduledfor": "Schedule For",
      "due": "Due",
      "duedate": "Due Date",
      "start": "Start",
      "startdate": "Start Date",
      "waitingfor": "Waiting for",
      "what": "What",
      "who": "Who",
      "tag": "Tag",
      "tags": "Tags",
      "area": "Area",
      "areas": "Areas",
      "aor": "Area of Responsibility",
      "aors": "Areas of Responsibility",
      "contact": "Contact",
      "contacts": "Contacts",
      "context": "Context",
      "contexts": "Contexts",
      "standalone": "Standalone",
      "move": "Move",
      "moveto": "Move to",
      "account": "Account",
      "accountsettings": "Account Settings",
      "preferences": "Preferences",
      "logout": "Logout",
      "help": "Help",
      "feedback": "Feedback",
      "search": "Search",
      "exportdata": "Export",
      "emptytrash": "Empty Trash",
      "collectnow": "Log Completed Items",
      "project": "Project",
      "newproject": "New Project",
      "task": "Task",
      "newtask": "New Task",
      "name": "Name",
      "note": "Note",
      "today": "Today",
      "tomorrow": "Tomorrow",
      "monday": "Monday",
      "tuesday": "Tuesday",
      "wednesday": "Wednesday",
      "thursday": "Thursday",
      "friday": "Friday",
      "saturday": "Saturday",
      "sunday": "Sunday",
      "mon": "Mon",
      "tue": "Tue",
      "wed": "Wed",
      "thu": "Thu",
      "fri": "Fri",
      "sat": "Sat",
      "sun": "Sun",
      "january": "January",
      "february": "February",
      "march": "March",
      "april": "April",
      "may": "May",
      "june": "June",
      "july": "July",
      "august": "August",
      "september": "September",
      "october": "October",
      "november": "November",
      "december": "December",
      "jan": "Jan",
      "feb": "Feb",
      "mar": "Mar",
      "apr": "Apr",
      "jun": "Jun",
      "jul": "Jul",
      "aug": "Aug",
      "sep": "Sep",
      "oct": "Oct",
      "nov": "Nov",
      "dec": "Dec",
      "loading": "Loading",
      "working": "Working",
      "langname": "English",
      "active": "Active",
      "inactive": "Inactive"
   },
   "es": {
      "inbox": "Bandeja de entrada",
      "focus": "\u00a0Foco",
      "next": "\u00a0Pr\u00f3ximo",
      "later": "Later",
      "waiting": "\u00a0Espera",
      "scheduled": "\u00a0Programado",
      "someday": "\u00a0Alg\u00fan d\u00eda",
      "projects": "\u00a0Proyectos",
      "logbook": "\u00a0Diario de a bordo",
      "trash": "\u00a0Basura",
      "collect": "\u00a0Recoger",
      "todos": "\u00a0Para Dos",
      "activeprojects": "\u00a0Proyectos Activos",
      "inactiveprojects": "\u00a0Inactivo Proyectos",
      "done": "\u00a0Hecho",
      "all": "\u00a0Todos",
      "none": "\u00a0Ninguno",
      "save": "\u00a0Guardar",
      "savechanges": "\u00a0Guardar cambios",
      "ok": "\u00a0Haga clic en Aceptar",
      "cancel": "\u00a0Cancelar",
      "areyousure": "\u00a0\u00bfEst\u00e1 seguro?",
      "effort": "\u00a0Esfuerzo",
      "time": "\u00a0Tiempo",
      "minutes": "\u00a0Acta",
      "hours": "\u00a0Horas",
      "hour": "\u00a0Hora",
      "orless": "\u00a0o menos",
      "energy": "\u00a0Energ\u00eda",
      "low": "\u00a0Bajo",
      "medium": "\u00a0Medio",
      "high": "\u00a0Alto",
      "scheduledfor": "\u00a0Calendario de",
      "due": "\u00a0Debido",
      "duedate": "\u00a0Fecha de Vencimiento",
      "start": "\u00a0Empezar",
      "startdate": "\u00a0Fecha de Inicio",
      "waitingfor": "\u00a0Waiting For",
      "what": "\u00a0\u00bfQu\u00e9",
      "who": "\u00a0\u00bfQui\u00e9n",
      "tag": "\u00a0Etiqueta",
      "tags": "\u00a0Etiquetas",
      "area": "\u00a0Zona",
      "areas": "\u00a0\u00c1reas",
      "aor": "\u00a0Area de Responsabilidad",
      "aors": "\u00a0\u00c1reas de responsabilidad",
      "contact": "\u00a0Contacto",
      "contacts": "\u00a0Contactos",
      "context": "\u00a0Contexto",
      "contexts": "\u00a0Contextos",
      "standalone": "\u00a0Independiente",
      "move": "\u00a0Mover",
      "moveto": "\u00a0Mover a",
      "account": "\u00a0Cuenta",
      "accountsettings": "\u00a0Configuraci\u00f3n de la cuenta",
      "preferences": "\u00a0Preferencias",
      "logout": "\u00a0Desconectarse",
      "help": "\u00a0Ayuda",
      "feedback": "\u00a0Realimentaci\u00f3n",
      "search": "\u00a0B\u00fasqueda",
      "exportdata": "\u00a0Exportaci\u00f3n",
      "emptytrash": "\u00a0Vaciar Papelera",
      "collectnow": "\u00a0Recoge art\u00edculos completados",
      "project": "\u00a0Proyecto",
      "newproject": "\u00a0Nuevo proyecto",
      "task": "\u00a0Tarea",
      "newtask": "\u00a0Nueva tarea",
      "name": "\u00a0Nombre",
      "note": "\u00a0Nota",
      "today": "\u00a0Hoy",
      "tomorrow": "\u00a0Ma\u00f1ana",
      "monday": "\u00a0Lunes",
      "tuesday": "\u00a0Martes",
      "wednesday": "\u00a0Mi\u00e9rcoles",
      "thursday": "\u00a0Jueves",
      "friday": "\u00a0Viernes",
      "saturday": "\u00a0S\u00e1bado",
      "sunday": "\u00a0Domingo",
      "mon": "\u00a0Lun",
      "tue": "\u00a0Mar",
      "wed": "\u00a0Casarse",
      "thu": "\u00a0Jue",
      "fri": "\u00a0Vie",
      "sat": "\u00a0S\u00e1b",
      "sun": "\u00a0Sol",
      "january": "\u00a0Enero",
      "february": "\u00a0Febrero",
      "march": "\u00a0Marzo",
      "april": "\u00a0Abril",
      "may": "\u00a0Mayo",
      "june": "\u00a0Junio",
      "july": "\u00a0Julio",
      "august": "\u00a0Agosto",
      "september": "\u00a0Septiembre",
      "october": "\u00a0Octubre",
      "november": "\u00a0De noviembre",
      "december": "\u00a0Diciembre",
      "jan": "\u00a0Jan",
      "feb": "\u00a0Febrero",
      "mar": "\u00a0Estropear",
      "apr": "\u00a0Abril",
      "jun": "\u00a0Junio",
      "jul": "\u00a0Julio",
      "aug": "\u00a0Agosto",
      "sep": "\u00a0Septiembre",
      "oct": "\u00a0Octubre",
      "nov": "\u00a0Noviembre",
      "dec": "\u00a0Diciembre",
      "loading": "\u00a0Cargando",
      "working": "\u00a0Laboral",
      "langname": "Spanish",
      "active": "Activos",
      "inactive": "Inactivo"
   }
};
