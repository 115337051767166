// --- NIRV GLOBAL OBJECT INIT ---
NIRV = {
  // CONSTANTS
  CONST: {
    // task.type
    TASK: 0,
    PROJECT: 1,
    REFITEM: 2,
    REFLIST: 3,

    // task.state
    INBOX: 0,
    NEXT: 1,
    WAITING: 2,
    SCHEDULED: 3,
    SOMEDAY: 4,
    LATER: 5,
    TRASHED: 6,
    LOGGED: 7,
    DELETED: 8,
    RECURRING: 9,
    REFERENCE: 10,
    ACTIVEPROJECT: 11,

    // task.ps
    PARALLEL: 0,
    SEQUENTIAL: 1,

    // tag.type
    CONTEXT: 0,
    AREA: 1,
    CONTACT: 2,
  },

  // LOCALIZATION
  L: {},
  language: 'en',
  languages: {},
  langtokens: {},

  // LOOK & FEEL
  // appearance: 'light',
  // platform: 'web',

  // PERSISTENT STORAGE (desktop)
  storage: {
    overquota: false,
    setItem: function (key, value) {
      // DEBUG && console.log('NIRV.storage.setItem('+key+')');
      try {
        if (key == 'authtoken' || key == 'u' || key == 'p') {
          return window.localStorage.setItem(key, value);
        } else if (!NIRV.storage.overquota) {
          return window.localStorage.setItem(key, value);
        }
      } catch (e) {
        console.error(e);
        NIRV.storage.overquota = true;
        var u = NIRV.storage.getItem('u');
        var authtoken = NIRV.storage.getItem('authtoken');
        NIRV.storage.clear();
        NIRV.storage.setItem('u', u);
        NIRV.storage.setItem('authtoken', authtoken);
      }
    },
    getItem: function (key) {
      // DEBUG && console.log('NIRV.storage.getItem('+key+')');
      return window.localStorage.getItem(key);
    },
    removeItem: function (key) {
      // DEBUG && console.log('NIRV.storage.removeItem('+key+')');
      return window.localStorage.removeItem(key);
    },
    key: function (key) {
      // DEBUG && console.log('NIRV.storage.key('+key+')');
      return window.localStorage.key(key);
    },
    clear: function () {
      // DEBUG && console.log('NIRV.storage.clear()');
      return window.localStorage.clear();
    },
  },

  // UI
  app_version: false,
  viewport: '',
  collapse: {
    collect: false,
    todos: false,
    activeprojects: false,
    inactiveprojects: true,
    done: false,
    tagcloud: true,
  },

  // TASK SELECT STATE
  editing: false,
  selecteditems: '',

  // COLLECTIONS
  cloudtags: [],
  taglists: {},
  tasklists: {},

  // GLOBAL RECALC
  dimensions: {},
  taskcounts: {
    due: {},
    action: {},
    standalone: {},
    p: {},
    l: {},
  },
  wtasks: {},

  // ENTITIES
  user: {},
  prefs: {},
  tags: {},
  tasks: {},
  appends: {},

  setUser: function (user) {
    DEBUG && console.log('  NIRV.setUser() ' + user.emailaddress);
    if (JSON.stringify(NIRV.user) !== JSON.stringify(user)) {
      NIRV.user = user;
      NIRV.storage.setItem('user', JSON.stringify(NIRV.user));
    }
    Sentry.setUser({
      email: user.emailaddress,
      id: user.id,
    });
  },

  // FILTERS
  currentarea: '__ALL__',
  currentview: 'focus',
  currentsearch: '',

  // desktop implementation
  currentfilters: {
    tags: [],
    due: false,
    etime: -1,
    energy: -1,
  },

  q: '',

  // AJAX
  baseurl_api: '',
  api: 'rest',
  authtoken: '',
  autosave: true,
  ajaxconnections: 0,
  ajax_initiated: 0,
  ajax_counter: 0,
  login_timestamp: 1,
  must_sync: true,
  nextSyncFromZero: false,
  ready: false,
  since: 0,
  setSince: function (since) {
    since = parseInt(since, 10);
    NIRV.since = isNaN(since) ? 0 : since;
    NIRV.storage.setItem('since', NIRV.since);
    DEBUG && console.log(' NIRV.since ' + NIRV.since);
  },
  sync_counter: 0,
  update_counter: 0,

  get: function (url, data, callback, type) {
    DEBUG && console.log('  GET ' + url);
    $.get(url, data, callback, type);
  },
  post: function (url, data, callback, type) {
    DEBUG && console.log('  POST ' + url);
    $.post(url, data, callback, type);
  },

  // DESKTOP ONLY
  topn: 3,
  moreless: {},
  mousedown: 'click',
  pos_selected: false,
  highlight_taskid: false,
  dialog_position_default: {},

  mouseIsDown: false,
  keyIsDown: false,

  isElectron: navigator.userAgent.indexOf('Electron') != -1 ? true : false,
  isWindows: navigator.userAgent.indexOf('Windows') != -1 ? true : false,
  isMac: navigator.userAgent.indexOf('Macintosh') != -1 ? true : false,

  version: NIRV_version,
};

// Base config
NIRV.ENV = 'PRODUCTION';

// desktop app
if (NIRV.isElectron) {
  if (NIRV.ENV === 'PRODUCTION') {
    NIRV.baseurl_account = 'https://account.nirvanahq.com/';
    NIRV.baseurl_nextapi = 'https://vercel-api.nirvanahq.com/api';
    NIRV.baseurl_api = 'https://focus.nirvanahq.com/api/';
    NIRV.baseurl_app = 'https://focus.nirvanahq.com/';
    NIRV.baseurl_www = 'https://www.nirvanahq.com/';
  }
  //
  else if (NIRV.ENV == 'LOCAL-JS') {
    NIRV.baseurl_account = 'http://m1a.local:4322/';
    NIRV.baseurl_nextapi = 'http://m1a.local:3001/api';
    NIRV.baseurl_api = 'http://m1a.local:3001/api';
    NIRV.baseurl_app = 'http://m1a.local:4321/';
    NIRV.baseurl_www = 'http://m1a.local:3000/';
  }
  //
  else if (NIRV.ENV == 'LOCAL-PHP') {
    NIRV.baseurl_account = 'https://m1-account.nirvanahq.com/';
    NIRV.baseurl_nextapi = 'http://m1a.local:3001/api';
    NIRV.baseurl_api = 'https://m1-focus.nirvanahq.com/api/';
    NIRV.baseurl_app = 'https://m1-focus.nirvanahq.com/';
    NIRV.baseurl_www = 'https://m1-www.nirvanahq.com/';
  }
}

// webapp
else {
  switch (location.hostname) {
    case 'focus.nirvanahq.com':
      NIRV.baseurl_account = 'https://account.nirvanahq.com/';
      NIRV.baseurl_nextapi = 'https://vercel-api.nirvanahq.com/api';
      NIRV.baseurl_api = 'https://focus.nirvanahq.com/api/';
      NIRV.baseurl_app = 'https://focus.nirvanahq.com/';
      NIRV.baseurl_www = 'https://www.nirvanahq.com/';
      break;

    case 'vercel-focus.nirvanahq.com':
      NIRV.baseurl_account = 'https://vercel-account.nirvanahq.com/';
      NIRV.baseurl_nextapi = 'https://vercel-api.nirvanahq.com/api';
      NIRV.baseurl_api = 'https://vercel-api.nirvanahq.com/api';
      NIRV.baseurl_app = 'https://vercel-focus.nirvanahq.com/';
      NIRV.baseurl_www = 'https://www.nirvanahq.com/';
      break;

    case 'eb-ehm-app.nirvanahq.com':
      NIRV.baseurl_account = 'https://dev-vercel-account.nirvanahq.com/';
      NIRV.baseurl_nextapi = 'https://dev-vercel-api.nirvanahq.com/api';
      NIRV.baseurl_api = 'https://dev-vercel-api.nirvanahq.com/api';
      NIRV.baseurl_app = 'https://eb-ehm-focus.nirvanahq.com/';
      NIRV.baseurl_www = 'https://dev-vercel-www.nirvanahq.com/';
      break;

    case 'dev-vercel-focus.nirvanahq.com':
      NIRV.baseurl_account = 'https://dev-vercel-account.nirvanahq.com/';
      NIRV.baseurl_nextapi = 'https://dev-vercel-api.nirvanahq.com/api';
      NIRV.baseurl_api = 'https://dev-vercel-api.nirvanahq.com/api';
      NIRV.baseurl_app = 'https://dev-vercel-focus.nirvanahq.com/';
      NIRV.baseurl_www = 'https://dev-vercel-www.nirvanahq.com/';
      break;

    case 'm1-focus.nirvanahq.com':
      NIRV.baseurl_account = 'https://m1-account.nirvanahq.com/';
      NIRV.baseurl_nextapi = 'http://m1a:3001/api';
      NIRV.baseurl_api = 'https://m1-focus.nirvanahq.com/api/';
      NIRV.baseurl_app = 'https://m1-focus.nirvanahq.com/';
      NIRV.baseurl_www = 'https://m1-www.nirvanahq.com/';
      break;

    case 'localhost':
    case 'm1a.local':
      NIRV.baseurl_account = 'http://m1a.local:4322/';
      NIRV.baseurl_nextapi = 'http://m1a.local:3001/api';
      NIRV.baseurl_api = 'http://m1a.local:3001/api';
      NIRV.baseurl_app = 'http://m1a.local:4321/';
      NIRV.baseurl_www = 'http://m1a.local:3000/';
      break;

    default:
      NIRV.baseurl_account = 'https://account.nirvanahq.com/';
      NIRV.baseurl_nextapi = 'https://vercel-api.nirvanahq.com/api';
      NIRV.baseurl_api = 'https://focus.nirvanahq.com/api/';
      NIRV.baseurl_app = 'https://focus.nirvanahq.com/';
      NIRV.baseurl_www = 'https://www.nirvanahq.com/';
      break;
  }
}

// DEBUG
// stale = () => {
//   const arr = [];
//   for (var i in NIRV.tags) {
//     if (NIRV.tags[i].__stale__) {
//       arr.push(i);
//     }
//   }
//   for (var i in NIRV.tasks) {
//     if (NIRV.tasks[i].__stale__) {
//       arr.push(i);
//     }
//   }
//   console.log(arr);
//   return arr;
// };
// setInterval(() => stale(), 1000);
