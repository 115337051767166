$(function () {
  function select_freq(v) {
    $('form.recurring select.freq').val(v);
    if (v == 'aftercompletion') {
      $('form.recurring div.aftercompletion').show();
      $('form.recurring div.interval').hide();
    } else {
      $('form.recurring div.aftercompletion').hide();
      $('form.recurring div.interval').show();
      $('form.recurring div.intervalx').hide();
      $('form.recurring div.intervalx.' + v).show();
    }
  }

  function select_ends(v) {
    if (v == 'count') {
      $('form.recurring span.until').hide();
      $('form.recurring span.count').css('visibility', 'visible');
    } else if (v == 'until') {
      $('form.recurring span.until').show();
      $('form.recurring span.count').css('visibility', 'hidden');
      var _until = new Date(
        $('form.recurring input[name=until]').val()
      ).toString('yyyyMMdd');
      var _nextdate = new Date(
        $('form.recurring input[name=nextdate]').val()
      ).toString('yyyyMMdd');
      if (
        $('form.recurring input[name=until]').val() == '' ||
        _until < _nextdate
      ) {
        $('form.recurring input[name=until]').val(yyyymmdd2mmddyyyy(_nextdate));
        $('form.recurring input[name=until_]').val(
          ymdtolocale(_nextdate, 'MDY')
        );
      }
    } else {
      // never
      $('form.recurring span.until').hide();
      $('form.recurring span.count').css('visibility', 'hidden');
    }
  }

  function select_spawnxdaysbefore(v) {
    $('form.recurring select.spawnxdaysbefore').val(v);
  }

  function nextup() {
    var recurring = NIRV.captureRecurringForm();
    var nextdates = NIRV.calcnextdates(JSON.parse(recurring));
    var prettydates = '';
    for (var i = 0; i < nextdates.length; i++) {
      if (i < 3) {
        prettydates += ' &nbsp;&rarr; ' + ymdtolocale(nextdates[i], 'MMMDY');
      }
      if (i == 3) {
        prettydates += ' ... ';
      }
    }
    $('form.recurring span.nextup').html(prettydates);

    if ($('.andon').is(':visible')) {
      $('a.minus').show();
    } else {
      $('a.minus').hide();
    }
  }

  // restrict 0-9
  $('body').on(
    'keyup',
    'form.recurring input.interval, form.recurring input.wait, form.recurring input.endsafterxtimes, form.recurring input.spawnxdaysbefore',
    function () {
      this.value = this.value.replace(/[^0-9]/g, ''); // http://stackoverflow.com/questions/891696
    }
  );

  // min value 1
  $('body').on(
    'blur',
    'form.recurring input.interval, form.recurring input.wait, form.recurring input.endsafterxtimes',
    function () {
      if (this.value == 0 || this.value == '') {
        this.value = 1;
      }
      nextup();
    }
  );

  // min value 0
  $('body').on('blur', 'form.recurring input.spawnxdaysbefore', function () {
    if (this.value == '') {
      this.value = 0;
    }
  });

  $('body').on('change', 'form.recurring select.freq', function () {
    select_freq($(this).val());
  });

  $('body').on('change', 'form.recurring input.interval', function () {
    var that = this;
    $(this)
      .closest('div.intervalx')
      .find('input.interval')
      .each(function () {
        $(this).val($(that).val());
      });
  });

  $('body').on('change', 'form.recurring select.month', function () {
    var $nth = $(this).closest('td').find('select.nth');
    var $day = $(this).closest('td').find('select.day');
    var $month = $(this);
    if ($nth.val() != 'last') {
      if ($month.val() == '02' && $nth.val() > 28) {
        $nth.val('last');
      } else if ($month.val() == '04' && $nth.val() > 30) {
        $nth.val('last');
      } else if ($month.val() == '06' && $nth.val() > 30) {
        $nth.val('last');
      } else if ($month.val() == '09' && $nth.val() > 30) {
        $nth.val('last');
      } else if ($month.val() == '11' && $nth.val() > 30) {
        $nth.val('last');
      }
    }
  });

  $('body').on('change', 'form.recurring select.day', function () {
    var $nth = $(this).closest('td').find('select.nth');
    var $day = $(this);
    if ($day.val() != 'day' && $nth.val() != 'last') {
      if ($nth.val() > 4) {
        $nth.val('last');
      }
    }
  });

  $('body').on('change', 'form.recurring select.nth', function () {
    var $nth = $(this);
    var $day = $(this).closest('td').find('select.day');
    if ($day.val() != 'day') {
      if ($nth.val() > 4) {
        $day.val('day');
      }
    }
    if ($(this).closest('div.intervalx').hasClass('yearly')) {
      var $month = $(this).closest('td').find('select.month');
      if ($nth.val() != 'last') {
        if ($month.val() == '02' && $nth.val() > 28) {
          $nth.val('last');
        } else if ($month.val() == '04' && $nth.val() > 30) {
          $nth.val('last');
        } else if ($month.val() == '06' && $nth.val() > 30) {
          $nth.val('last');
        } else if ($month.val() == '09' && $nth.val() > 30) {
          $nth.val('last');
        } else if ($month.val() == '11' && $nth.val() > 30) {
          $nth.val('last');
        }
      }
    }
  });

  $('body').on('click', 'form.recurring a.plus', function (e) {
    var $tr = $(this).closest('tr');
    // var $table = $tr.closest('table');
    $tr.clone().insertAfter($tr);
    nextup();
    e.preventDefault();
  });

  $('body').on('click', 'form.recurring a.minus', function (e) {
    var $tr = $(this).closest('tr');
    if ($tr.siblings().length != 0) {
      $tr.remove();
      nextup();
    }
    e.preventDefault();
  });

  $('body').on('change', 'form.recurring select.ends', function () {
    select_ends($(this).val());
  });

  $('body').on('change', 'form.recurring input#hasduedate', function () {
    if ($(this).prop('checked')) {
      $('form.recurring span.spawnxdaysbefore').css('visibility', 'visible');
      $('form.recurring span.due').show();
      $('form.recurring span.created').hide();
    } else {
      $('form.recurring span.spawnxdaysbefore').css('visibility', 'hidden');
      $('form.recurring span.due').hide();
      $('form.recurring span.created').show();
    }
  });

  $('body').on(
    'change',
    'form.recurring input, form.recurring select',
    function () {
      nextup();
    }
  );

  NIRV.form_recurring = function () {
    $('form.recurring').each(function () {
      var recurring = {};
      if ($(this).attr('taskid') == 'taskedit') {
        // invoked from edit
        var recurringstr = $('li.task.edit')
          .find('input[name=recurring]')
          .val();
        if (recurringstr == '') {
          recurring = {
            freq: 'daily',
            interval: 1,
            nextdate: today(),
            hasduedate: 0,
            paused: false,
          };
        } else {
          recurring = JSON.parse(recurringstr);
        }
      } else {
        var task = NIRV.tasks[$(this).attr('taskid')];
        if (task.recurring.trim() == '') {
          recurring = {
            freq: 'daily',
            interval: 1,
            nextdate: today(),
            hasduedate: 0,
            paused: false,
          };
        } else {
          recurring = JSON.parse(task.recurring);
        }
      }

      $('form.recurring div.intervalx.daily > table').append(
        NIRV.renderRecurringFormDaily()
      );
      $('form.recurring div.intervalx.weekly > table').append(
        NIRV.renderRecurringFormWeekly()
      );
      $('form.recurring div.intervalx.monthly > table').append(
        NIRV.renderRecurringFormMonthly()
      );
      $('form.recurring div.intervalx.yearly > table').append(
        NIRV.renderRecurringFormYearly()
      );

      // update form UI
      if (recurring.freq == 'daily') {
        $('form.recurring div.intervalx.daily > table').html(
          NIRV.renderRecurringFormDaily()
        );
        $('form.recurring div.intervalx.daily input.interval').val(
          recurring.interval
        );
      } else if (recurring.freq == 'weekly') {
        $('form.recurring div.intervalx.weekly > table').empty();
        for (var i in recurring.on) {
          $('form.recurring div.intervalx.weekly > table').append(
            NIRV.renderRecurringFormWeekly()
          );
          $('form.recurring div.intervalx.weekly > table select.day:last').val(
            recurring.on[i]
          );
        }
        $('form.recurring div.intervalx.weekly input.interval').val(
          recurring.interval
        );
      } else if (recurring.freq == 'monthly') {
        $('form.recurring div.intervalx.monthly > table').empty();
        for (var i in recurring.on) {
          $('form.recurring div.intervalx.monthly > table').append(
            NIRV.renderRecurringFormMonthly()
          );
          $('form.recurring div.intervalx.monthly > table select.nth:last').val(
            recurring.on[i].nth
          );
          $('form.recurring div.intervalx.monthly > table select.day:last').val(
            recurring.on[i].day
          );
        }
        $('div.intervalx.monthly input.interval').val(recurring.interval);
      } else if (recurring.freq == 'yearly') {
        $('form.recurring div.intervalx.yearly > table').empty();
        for (var i in recurring.on) {
          $('form.recurring div.intervalx.yearly > table').append(
            NIRV.renderRecurringFormYearly()
          );
          $('form.recurring div.intervalx.yearly > table select.nth:last').val(
            recurring.on[i].nth
          );
          $('form.recurring div.intervalx.yearly > table select.day:last').val(
            recurring.on[i].day
          );
          $(
            'form.recurring div.intervalx.yearly > table select.month:last'
          ).val(recurring.on[i].month);
        }
        $('div.intervalx.yearly input.interval').val(recurring.interval);
      }

      select_freq(recurring.freq);
      select_spawnxdaysbefore(recurring.spawnxdaysbefore);

      $('form.recurring input[name=nextdate]').val(
        yyyymmdd2mmddyyyy(recurring.nextdate)
      );
      $('form.recurring input[name=nextdate_]').val(
        ymdtolocale(recurring.nextdate, 'MDY')
      );
      if (recurring.until != undefined) {
        $('form.recurring select.ends').val('until');
        $('form.recurring input[name=until]').val(
          yyyymmdd2mmddyyyy(recurring.until)
        );
        $('form.recurring input[name=until_]').val(
          ymdtolocale(recurring.until, 'MDY')
        );
        $('form.recurring span.count').css('visibility', 'hidden');
        $('form.recurring span.until').show();
      } else if (recurring.count != undefined) {
        $('form.recurring select.ends').val('count');
        $('form.recurring input.count').val(recurring.count);
        $('form.recurring span.count').css('visibility', 'visible');
        $('form.recurring span.until').hide();
      } else {
        $('form.recurring select.ends').val('never');
        $('form.recurring span.count').css('visibility', 'hidden');
        $('form.recurring span.until').hide();
      }
      if (recurring.hasduedate == 1) {
        var spawnxdaysbefore = recurring.spawnxdaysbefore || 1;
        $('form.recurring input.hasduedate').prop('checked', true);
        $('form.recurring span.spawnxdaysbefore').css('visibility', 'visible');
        $('form.recurring input.spawnxdaysbefore').val(spawnxdaysbefore);
        $('form.recurring span.due').show();
        $('form.recurring span.created').hide();
      } else {
        $('form.recurring input.hasduedate').prop('checked', false);
        $('form.recurring span.spawnxdaysbefore').css('visibility', 'hidden');
        $('form.recurring span.due').hide();
        $('form.recurring span.created').show();
      }

      if (recurring.paused == true) {
        $('form.recurring input.paused').val('true');
      }

      // 3. calculate next dates based on form UI
      nextup();
    });
  };
});
