NIRV.themes = function () {
  return [
    'default',
    'light',
    'dark',
    'night',
    'blue',
    'green',
    'violet',
    'orange',
    'red',
    'teal',
  ];
};

NIRV.activateDarkMode = function () {
  $('html').removeClass('appearance--light').addClass('appearance--dark');
};

NIRV.activateLightMode = function () {
  $('html').removeClass('appearance--dark').addClass('appearance--light');
};

NIRV.applyTheme = function () {
  // DEBUG && console.log('NIRV.applyTheme()');

  var $html = $('html');
  $html.attr('class', '');

  // PLATFORM
  if (NIRV.isMac) {
    $html.addClass('os--mac');
  }
  if (NIRV.isWindows) {
    $html.addClass('os--windows');
  }
  if (NIRV.isElectron) {
    $html.addClass('browser--electron');
  }

  // DENSITY - compact | comfortable
  if (
    typeof NIRV.prefs.UIBDensity !== 'undefined' &&
    NIRV.prefs.UIBDensity.value
  ) {
    $html.addClass('density--' + NIRV.prefs.UIBDensity.value);
  } else {
    $html.addClass('density--compact');
  }

  // APPEARANCE - light | dark | auto (sync with os)
  if (NIRV.prefs.UIBAppearance && NIRV.prefs.UIBAppearance.value == 'light') {
    NIRV.activateLightMode();
  } else if (
    NIRV.prefs.UIBAppearance &&
    NIRV.prefs.UIBAppearance.value == 'dark'
  ) {
    NIRV.activateDarkMode();
  } else {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      NIRV.activateDarkMode();
    } else {
      NIRV.activateLightMode();
    }
  }

  // THEME NAME - classic | pink | orange | green ...
  // if (NIRV.user.servicelevel == 'basic') {
  //   $html.addClass('theme--blue');
  // } else if (
  //   typeof NIRV.prefs.UIBThemeName !== 'undefined' &&
  //   NIRV.prefs.UIBThemeName.value
  // ) {
  //   $html.addClass('theme--' + NIRV.prefs.UIBThemeName.value);
  // } else {
  //   $html.addClass('theme--blue');
  // }
  $html.addClass('theme--blue');

  // BACKDROP - snow | slate
  if ($html.hasClass('appearance--light')) {
    if (
      NIRV.prefs.UIBThemeBackground &&
      NIRV.prefs.UIBThemeBackground.value == 'light'
    ) {
      $html.addClass('backdrop--snow');
    } else if (
      NIRV.prefs.UIBThemeBackground &&
      NIRV.prefs.UIBThemeBackground.value == 'dark'
    ) {
      $html.addClass('backdrop--slate');
    } else {
      $html.addClass('backdrop--snow');
    }
  }

  // -----------------------

  var style = '';
  style += '<style>';

  // FONT FAMILY
  if (NIRV.prefs.UIB3FontFamily != undefined) {
    style +=
      'html { font-family: ' +
      NIRV.prefs.UIB3FontFamily.value +
      ', sans-serif; } ';
  }

  style += '</style>';
  $('#theme').html(style);

  // NIRV.reflow();
};
